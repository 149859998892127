import React, { useState, useEffect } from 'react';
import { Page } from 'react-onsenui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RenderToolbar from '../RenderToolbar';
import PaymentMethodsForm from './PaymentMethodsForm';
import * as UserActions from '../../actions/user';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { selectUserPaymentMethods } from '../../reducers/user';
import PaymentMethodsList from './PaymentMethodsList';


function PaymentMethods(props) {
  const { popPage, actions, user, paymentMethods } = props;
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    actions.getUserPaymentMethods(user.id).then(response => {
      console.log(response);
    });
  }, []);

  const handleSubmit = values => {
    setSubmitting(true);

    actions.addUserPaymentMethod(user.id, values).then(response => {
      console.log(response);

      popPage();
    });

    setSubmitting(false);
  }

  const handlePaymentMethodRemoveClicked = paymentMethod => {
    const confirmed = window.confirm('Are you sure you want to remove this payment method');

    if (confirmed) {
      actions.destroyUserPaymentMethod({ payment_method: paymentMethod }).then(response => {
        console.log(response);
      });
    }
  }

  const handleDefaultPaymentMethodClicked = paymentMethod => {
    actions.updateUserDefaultPaymentMethod(user.id, { payment_method_id: paymentMethod.id }).then(response => {
      console.log(response);
    });
  }

  const renderToolbar = () => <RenderToolbar backAction={popPage} />

  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <Elements>
        <Page className="payment-methods" renderToolbar={renderToolbar}>
              <h1 className="u-text-center">Payment Methods</h1>

          {paymentMethods && paymentMethods.length > 0 && (
            <PaymentMethodsList
              paymentMethods={paymentMethods}
              onPaymentMethodRemoveClicked={handlePaymentMethodRemoveClicked}
              onDefaultPaymentMethodClicked={handleDefaultPaymentMethodClicked}
              showRemove={true}
              showSetDefault={true}
              defaultPaymentMethodId={user.default_payment_method_id}
            />
          )}

          <PaymentMethodsForm onSubmit={handleSubmit} submitting={submitting}/>

          <div className="c-payment-method__stripe-logo">
            <a href="https://stripe.com/nz/legal/consumer" target="_blank" rel="noreferrer">
              <img  src="stripe-black.svg" />
            </a>
          </div>
        </Page>
      </Elements>
    </StripeProvider>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    paymentMethods: selectUserPaymentMethods(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);