import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouterNavigator, RouterUtil } from 'react-onsenui';
import { selectCurrentOrders } from '../reducers/user';
import CurrentOrders from './orders/CurrentOrders';

class Navigator extends Component {
  constructor(props) {
    super(props);

    console.log('navigator props', props);

    this.state = {
      routeConfig: RouterUtil.init([{
        component: props.tab.component,
        props: {
          key: 'home',
          pushPage: (...args) => this.pushPage(...args),
          setActiveTab: props.setActiveTab
        }
      }])
    }
  }

  pushPage(page, key, props) {
    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.push({
      routeConfig,
      route: {
        component: page,
        props: {
          key: key,
          popPage: () => this.popPage(),
          pushPage: (...args) => this.pushPage(...args),
          resetPageStack: (...args) => this.resetPageStack(...args),
          resetPageStackToCurrentOrder: (...args) => this.resetPageStackToCurrentOrder(...args),
          ...props,
        }
      }
    });

    this.props.onStackChange(routeConfig.routeStack.length + routeConfig.processStack.length);

    this.setState({routeConfig});
  }

  popPage(options = {}) {
    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.pop({
      routeConfig,
      options: {
        ...options
      }
    });

    this.props.onStackChange(routeConfig.routeStack.length - routeConfig.processStack.length);
    
    this.setState({routeConfig});
  }

  resetPageStackToCurrentOrder() {
    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.reset({
      routeConfig,
      route: {
        component: this.props.tab.component,
        props: {
          key: 'home',
          pushPage: (...args) => this.pushPage(...args),
        }
      }
    });

    routeConfig = RouterUtil.push({
      routeConfig,
      route: {
        component: CurrentOrders,
        props: {
          key: 'current-orders',
          currentOrders: this.props.currentOrders,
          popPage: () => this.popPage(),
          pushPage: (...args) => this.pushPage(...args),
          resetPageStack: (...args) => this.resetPageStack(...args),
        }
      }
    });

    this.props.onStackChange(1);

    this.setState({routeConfig});
  }

  resetPageStack() {
    let routeConfig = this.state.routeConfig;

    routeConfig = RouterUtil.reset({
      routeConfig,
      route: {
        component: this.props.tab.component,
        props: {
          key: 'home',
          pushPage: (...args) => this.pushPage(...args),
        }
      }
    });

    this.props.onStackChange(1);

    this.setState({routeConfig});
  }

  onPostPush() {
    this.setState(prev => ({
      routeConfig: RouterUtil.postPush(prev.routeConfig)
    }), () => {
      requestAnimationFrame(() => {
        if (this.navigatorRef._navi && this.navigatorRef._navi.lastChild) {
          // Hack for onsen reset bug, ensures that the page we reset to is displayed
          this.navigatorRef._navi.lastChild.style.display = "block";
        }
      });
    });
  }

  onPostPop() {
    const routeConfig = RouterUtil.postPop(this.state.routeConfig);
    
    this.setState({routeConfig});
  }

  renderPage(route) {
    const props = route.props || {};
    
    return <route.component {...props} />
  }

  render() {
    const { routeConfig } = this.state;

    console.log('Route Config', routeConfig);

    return (
      <RouterNavigator
        ref={e => this.navigatorRef = e}
        swipeable={true}
        swipePop={options => this.popPage(options)}
        routeConfig={routeConfig}
        renderPage={this.renderPage}
        onPostPush={() => this.onPostPush()}
        onPostPop={() => this.onPostPop()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.settings,
    isAuthenticated: state.user.loggedIn,
    currentOrders: selectCurrentOrders(state)
  }
}

export default connect(mapStateToProps)(Navigator)