import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Form } from 'reactstrap';
import ons from 'onsenui';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import Loading from '../Loading';
import { connect } from 'react-redux';


let RegisterForm = props => {
  const { handleSubmit, submitting, beans, termsAndConsVal } = props;

  const isAndroid = ons.platform.isAndroid();

  console.log(termsAndConsVal)

  return (
    <Form onSubmit={handleSubmit} className="c-form">
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field
          id="first_name"
          name="first_name"
          type="text"
          placeholder="First Name"
          validate={Validators.required}
          component={renderField}
        />
      </div>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field
          id="last_name"
          name="last_name"
          type="text"
          placeholder="Last Name"
          validate={Validators.required}
          component={renderField}
        />
      </div>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field
          id="email"
          name="email"
          type="text"
          placeholder="Email"
          validate={[Validators.required, Validators.email, Validators.lowerCaseEmail]}
          component={renderField}
        />
      </div>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field
          id="phone"
          name="phone"
          type="phone"
          placeholder="Phone"
          validate={Validators.required}
          component={renderField}
        />
      </div>

      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          validate={[Validators.required, Validators.password, Validators.letterAndNumber]}
          component={renderField}
        />
      </div>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          placeholder="Confirm Password"
          validate={[Validators.required, Validators.password, Validators.confirmPassword]}
          component={renderField}
        />
      </div>

      {/* <p className="u-text-center">Select your preferred beans</p>
        <div className="c-form__checkbox-group">
          <Field
          id="beans"
          name="beans"
          type="checkboxGroup"
          placeholder="Bean Preference"
          component={renderField}
          options={beans.map(b => ({ name: b.name, id: b.id }))}
          />
        </div> */}
      

      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <div className="c-form__checkbox">
          <Field
            id="terms_conditions"
            name="terms_conditions"
            type="checkbox"
            placeholder="Confirm Password"
            validate={Validators.required}
            component={renderField}
          />

          <label>I agree to the terms and conditions and privacy policy.</label>
        </div>
      </div>

      <div>
        <a className="u-text-black" href="https://quicky.cafe/buyer-terms" target="_blank" rel="noreferrer">
          View Terms and Conditions
        </a>
        <br />
        <a className="u-text-black" href="https://quicky.cafe/privacy-policy" target="_blank" rel="noreferrer">
          View Privacy Policy
        </a>
      </div>

      <div className="u-mt-1">
        {submitting 
          ? <Loading />
          : (
            <button 
              type="submit" 
              disabled={!termsAndConsVal}
              className={isAndroid ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'}
            >
              Submit
            </button>
          )}
      </div>
    </Form>
  )
}

RegisterForm =  reduxForm({
  form: 'RegisterForm'
})(RegisterForm)

const selector = formValueSelector('RegisterForm');

function mapStateToProps(state) {
  return {
    termsAndConsVal: selector(state, 'terms_conditions')
  }
}

export default connect(mapStateToProps)(RegisterForm)