import React from 'react';
import { connect } from 'react-redux';
import SignupActivate from './login/SignupActivate';
import Login from './login/Login';
import ResetPasswordConfirm from './login/ResetPasswordConfirm';
import TabBar from './tabs/TabBar';

function Main(props) {
  const { isAuthenticated, navigator } = props;
  
  // const [shouldRefresh, setShouldRefresh] = useState(false);
  // const [refreshing, setRefreshing] = useState(false);

  // useEffect(() => {
  //   if(isAuthenticated && isInStandaloneMode()){
  //     document.addEventListener('visibilitychange', handleVisibilityChange);
  //   }

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   }
  // }, [isAuthenticated])

  // const handleVisibilityChange = () => {
  //   if (document.visibilityState === 'visible') {
  //     setShouldRefresh(true);
  //   }
  // }

  // const handleRefresh = () => {
  //   setRefreshing(true);
  //   window.location.reload();
  // }

  // const isInStandaloneMode = () => (('standalone' in window.navigator) && window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches;

  if(window.location.pathname.includes('signupActivate')) {
    return <SignupActivate navigator={navigator} />;
  
  } else {
    return isAuthenticated 
      ? <>
        {/* {shouldRefresh ? <div className='c-btn__sync'>
          <Button className={`${refreshing ? 'u-bg--transparent' : 'u-bg--black'}`} onClick={() => handleRefresh()}>
            {refreshing ? <ProgressCircular indeterminate={true} /> : sync()}
          </Button>
        </div>
          : null} */}

        <TabBar navigator={navigator} />
      </>
      : window.location.pathname.includes('passwordReset') 
        ? <ResetPasswordConfirm navigator={navigator}/>
        : <Login navigator={navigator}/>;
  }  
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.loggedIn
  }
}

export default connect(mapStateToProps)(Main);