import React, { useEffect } from 'react';
import { Page, ProgressCircular } from 'react-onsenui';
import { connect } from 'react-redux';
import RenderToolbar from '../RenderToolbar';
import { Form, reduxForm, Field } from 'redux-form';
import * as UserActions from '../../actions/user';
import { bindActionCreators } from 'redux';
import * as Validators from '../Validators';
import { renderField } from '../RenderField';
import ons from 'onsenui';
import { selectUserBeanIds } from '../../reducers/user';
import Loading from '../Loading';

function CofeePreferencesForm(props) {
  const { popPage, submitting, handleSubmit, beans, actions, user } = props;

  const isAndroid = ons.platform.isAndroid();

  const handleFormSubmit = values => {
    return actions.updateUserBeanPreferences(values, user.id).then(response => {
      console.log(response);

      popPage();
    });
  }

  const renderToolbar = () => <RenderToolbar backAction={popPage}/>

  return (
    <Page className="preferred-beans" renderToolbar={renderToolbar}>
      <h1 className="u-text-center">Preferences</h1>

      <Form className="c-form c-form__preferences" onSubmit={handleSubmit(handleFormSubmit)}>
        <Field
          id="beans"
          name="beans"
          type="checkboxGroup"
          placeholder="Bean Preference"
          validate={Validators.required}
          component={renderField}
          options={beans.map(b => ({ name: b.name, id: b.id }))}
        />

        <div className="c-form__button-group u-mt-2">
          {submitting 
            ? <Loading/>
            : (
              <button type="submit" className={isAndroid ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'}>Save</button>
            )}
        </div>
      </Form>
    </Page>
  );
}

CofeePreferencesForm = reduxForm({
  form: 'CoffeePreferencesForm'
})(CofeePreferencesForm)

function mapStateToProps(state) {
  return {
    user: state.user,
    beans: state.beans,
    initialValues: {
      beans: selectUserBeanIds(state)
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CofeePreferencesForm)