import React from 'react';
import { Card } from 'react-onsenui';


const cloudinaryCore = require('cloudinary-core');
const cld = cloudinaryCore.Cloudinary["new"]({cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME});

export function DrinkStep(props) {
  const { products, selectedProduct, onDrinkSelected, onDrinkUnselected } = props;

  return (
    <div className="c-cafe__drinks">
      {products?.length > 0 && products.map((product, i) => 
        product.available ? (
          <Card 
            key={i} 
            className={`c-cafe__drinks__item card` + (selectedProduct ? (selectedProduct === product.id ? ' c-cafe__drinks__item--selected' : ' c-cafe__drinks__item--not-selected') : ' null')}           
            onClick={() => {
              if (!product.available) return;

              onDrinkSelected(selectedProduct ? null : product.id);
              if(selectedProduct) onDrinkUnselected();
            }}
          >
            <div className={`c-cafe__drinks__image c-cafe-drink__${product.name}`} style={{backgroundImage: `url(${cld.url(product.image)})`}}></div>

            <div>
              <p className="c-cafe__drinks__text">{product.name}</p>
              <p className="c-cafe__drinks__sold-out">{!product.available && 'Sold out'}</p>
            </div>
          </Card>
        ) : null
      )}
    </div>
  );
}