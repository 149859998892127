import React from 'react'
import ReactDOM from 'react-dom'
import { Provider} from 'react-redux';
import configureAppStore from '../storeConfig';
import Main from './Main';
import { authCheck, getUser, logout, updateUserOrderStatus, delayOrderPickupTime } from '../actions/user';
import { Navigator } from 'react-onsenui';
import { AJAX_CALL_ERROR, AUTH_CHECK } from '../actionTypes';
import ServiceWorker from '../serviceWorker';
import ReduxToastr from 'react-redux-toastr';

import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '../sass/app.scss';

const store = configureAppStore();

store.dispatch(authCheck()).then(response => {
  console.log(response);
  if (response.type === AUTH_CHECK && response.payload) {

    console.log('auth check payload', response.payload);

    store.dispatch(getUser()).then(response => {
      console.log(response);

      if (response.type === AJAX_CALL_ERROR) {
        store.dispatch(logout());
      }
    });

    new ServiceWorker().init();
  }
});

ReactDOM.render(
  <Provider store={store}>
    <div>
      <ReduxToastr
        position="top-center"
        showCloseButton
        closeOnToastrClick
        timeOut={0} />
    </div>
    
    <Navigator
      renderPage={(route, navigator) => <route.component navigator={navigator}/>}
      initialRoute={{
        component: Main,
      }}
      swipeable={true}
    />
  </Provider>,
  document.getElementById('app')
);