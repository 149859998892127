import React from "react";
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, InfoWindow, Marker, DirectionsRenderer } from "react-google-maps";
import { Image } from 'cloudinary-react';

const SimpleMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`, //&libraries=geometry,drawing,places
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    mapOptions: {
      styles: [
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eee5da"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eee5da"
            }
          ]
        },
        {
          "featureType": "landscape.natural.landcover",
          "stylers": [
            {
              "color": "#bea380"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ],
      streetViewControl: false,
      disableDefaultUI: true,
    },
    icons: {
      1: {
        url: '/images/marker_black.png',
        size: { width: 127, height: 191 },
        scaledSize: { width: 21, height: 32 },
        anchor: { x: 0, y: 32 },
      }
    }
  }),
  lifecycle({
    componentWillMount() {
      this.refs = {};

      this.setState({
        bounds: null,
        updateBoundsOnInit: true,
        center: {
          lat: -36.848461, lng: 174.763336
        },
        cardOpen: null,
        zoom: 12,
        marker: this.props.marker,
        draggable: this.props.draggable,
        currentLocation: null,
        directions: null,
        onMapMounted: ref => {
          this.refs.map = ref;

          if (this.props.position) {
            this.setState({
              currentLocation: this.props.position,
              center: {
                lat: this.props.position.lat,
                lng: this.props.position.lng
              },
              zoom: 15,
            });
          }

          if (typeof window.google !== 'undefined' && this.props.position && this.props.marker) {
            const DirectionsService = new window.google.maps.DirectionsService();

            DirectionsService.route({
              origin: new window.google.maps.LatLng(this.props.position.lat, this.props.position.lng),
              destination: new window.google.maps.LatLng(this.props.marker.lat, this.props.marker.lng),
              travelMode: window.google.maps.TravelMode.WALKING,
            }, (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                this.setState({
                  directions: result
                });
              } else {
                console.error(`error fetching directions ${result}`);
              }
            });
          }

          console.log('on map mounted')
        },
        onBoundsChanged: () => {
          if (this.state.updateBoundsOnInit) {
            if (typeof window.google !== 'undefined' && typeof this.refs.map !== 'undefined' && this.props.marker) {
              this.setState({ updateBoundsOnInit: false });

              this.state.resetBounds();
            }
          }
        },
        setCardOpen: (open) => {
          this.setState({ cardOpen: open });
        },
        resetBounds: () => {
          console.log('reset bounds')

          if (typeof window.google === 'undefined') return false;

          const bounds = new window.google.maps.LatLngBounds();

          if (this.props.position && this.props.marker) {
            const positionLatLng = new window.google.maps.LatLng(Number(this.props.position.lat), Number(this.props.position.lng));
            const positionMarker = new window.google.maps.Marker({ position: positionLatLng });

            bounds.extend(positionMarker.position);

            const latLng = new window.google.maps.LatLng(Number(this.props.marker.lat), Number(this.props.marker.lng));
            const m = new window.google.maps.Marker({ position: latLng });

            bounds.extend(m.position);
          }

          // Don't zoom in too far on only one marker
          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            const extra = 0.001;
            const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + extra, bounds.getNorthEast().lng() + extra);
            const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - extra, bounds.getNorthEast().lng() - extra);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
          }

          this.refs.map.fitBounds(bounds);
        }
      })
    },
    componentDidUpdate(prevProps) {
      if (this.props.marker !== prevProps.marker) {
        let s = {
          marker: this.props.marker
        };

        if (this.props.marker) {
          if (this.props.marker === 1) {
            s.center = this.props.marker.position;
          }
        }

        this.setState(s);
      }
    }
  }),
  withScriptjs,
  withGoogleMap,
)((props) =>
  <GoogleMap
    ref={props.onMapMounted}
    options={props.mapOptions}
    zoom={props.zoom}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
  >
    <DirectionsRenderer
      directions={props.directions}
      options={{
        polylineOptions: {
          strokeColor: '#000',
          strokeWeight: 5
        }
      }}
    />

    {props.currentLocation && (
      <Marker
        key="currentLocation"
        position={props.currentLocation}
        icon="/images/current-location.png" />
    )}

    {props.marker && (
      <Marker
        key="shop"
        position={{ lat: Number(props.marker.lat), lng: Number(props.marker.lng) }}
        icon={props.icons[1]}
        onClick={() => props.setCardOpen(true)}
      >
        {props.cardOpen &&
          <InfoWindow onCloseClick={() => props.setCardOpen(false)}><div className="c-map__card">
            {props.marker.banner && <Image cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={props.marker.banner} className="c-map__card-image" />}

            <div className="c-map__card-content">
              <div className="c-map__card-title">
                <h4>{props.marker.name}</h4>
              </div>

              <p>{props.marker.description}</p>

              <p>{props.marker.address}</p>
            </div>
          </div>
          </InfoWindow>}
      </Marker>)}
  </GoogleMap>
)

export default SimpleMap;