import React from 'react';
import { Button } from 'react-onsenui';


export function DrinkSizeStep(props) {
  const { sizeVariations, selectedSize, onSizeSelected } = props;

  sizeVariations.sort((a, b) => {
    if (b.name === 'Small') return 1;
    if (b.name === 'Medium') return a.name === 'Small' ? -1 : 1;
    if (b.name === 'Large') return -1;

    return 0;
  });

  return (
    <>
      <div className="c-text__header">
        <h2>OK,<br/>How'd you like that?</h2>
      </div>

      <h3 className="u-text-center u-mt-1">Size</h3>
      
      <div className="c-drink__sizes">
        {sizeVariations.length > 0 && sizeVariations.map(v => (
          <div key={`variation-${v.id}`} className={"c-drink__sizes__item" + (selectedSize === v.id ? ' c-drink__sizes__item--selected' : '')}>
            <Button className={`c-drink__sizes__${v.name}`} onClick={() => {onSizeSelected(v.id)}}>

              {(v.name === 'Small' || v.name === 'S') &&
                  <svg className="svg_cup cup_small" viewBox="0 0 117 126">
                      <polygon className="st0" points="17.5,120.5 3.6,9.5 112.4,9.5 98.5,120.5"/>
                      <path d="M111.9,10L98,120H18L4.1,10H111.9 M113,9H3l14.1,112h81.8L113,9L113,9z"/>
                  </svg>
              } 

              {(v.name === 'Medium'  || v.name === 'M') &&
                  <svg className="svg_cup cup_medium" viewBox="0 0 135 162">
                      <polygon className="st0" points="21.6,156.5 4.6,9.5 129.4,9.5 112.4,156.5"/>
                      <path d="M128.9,10L112,156H22L5.1,10H128.9 M130,9H4l17.1,148h91.8L130,9L130,9z"/>
                  </svg>
              } 

              {(v.name === 'Large' || v.name === 'L') &&
                <svg className="svg_cup cup_large" viewBox="0 0 144 207">
                    <polygon className="st0" points="26.6,201.5 9.5,9.5 134.5,9.5 117.4,201.5"/>
                    <path d="M133.9,10L117,201H27L10.1,10H133.9 M135,9H9l17.1,193h91.8L135,9L135,9z"/>
                </svg>
              }
              
              <p className="c-drink__size__text">{v.name}</p>

            </Button>
          </div>
        ))}
      </div>
    </>
  );
}