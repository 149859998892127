import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ListItem, List, Button } from 'react-onsenui';
import * as ShopActions from '../../actions/shop';
import * as UserActions from '../../actions/user'
import { bindActionCreators } from 'redux';
import OrderDrinkForm from '../drink/OrderDrinkForm';
import { selectShopsBySearchTerm } from '../../reducers/shops';
import { selectUserBeanIds } from '../../reducers/user';
import Loading from '../Loading';
import { Image } from 'cloudinary-react';
import { Input } from 'react-onsenui';
import * as dayjs from 'dayjs';

const asin = Math.asin
const cos = Math.cos
const sin = Math.sin
const PI_180 = Math.PI / 180;

const hav = (x) => {
  const s = sin(x / 2)
  return s * s
}

const relativeHaversineDistance = (lat1, lon1, lat2, lon2) => {
  const aLatRad = lat1 * PI_180
  const bLatRad = lat2 * PI_180
  const aLngRad = lon1 * PI_180
  const bLngRad = lon2 * PI_180

  const ht = hav(bLatRad - aLatRad) + cos(aLatRad) * cos(bLatRad) * hav(bLngRad - aLngRad)
  return asin(ht)
}

class CafeList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nearbySelected: true,
      time: Date.now()
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getShops().then(response => {
      console.log(response);
    });

    // Refresh incase a shop has closed
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleCafeClicked = (id, isOpen) => {
    if (isOpen) {
      const { pushPage, actions, selectedShop, cartItems } = this.props;

      if (selectedShop && cartItems.length > 0 && selectedShop != id) {
        const answer = window.confirm(`You already have an order for ${selectedShop} in your cart, would you like to clear this order?`);
        if (!answer) {
          return;
        }

        actions.clearCartItems();
      }

      actions.setSelectedShop(id);

      pushPage(OrderDrinkForm, 'order-form', { shopId: id });
    }
  }

  handleRecentClicked = () => {
    this.setState({
      nearbySelected: false,
      // dropDownOpen: false
    })
  }

  handleNearbyClicked = () => {
    // if (this.state.nearbySelected) {
    //   this.setState({ dropDownOpen: !this.state.dropDownOpen });
    // } else 
    this.setState({ nearbySelected: true });
  }

  renderRow = (row, id) => {
    const { userBeanIds } = this.props;

    const weekday = dayjs().format('dddd').toLowerCase();
    const preferredBean = row.beans.length > 0 && userBeanIds.includes(row.beans[0].id);
    let isOpen = row[`open_${weekday}`];

    if (isOpen && row[`opening_time_${weekday}`] && row[`closing_time_${weekday}`]) {
      const now = dayjs();
      const openingTime = dayjs(row[`opening_time_${weekday}`], 'HH:mm:ss');
      const closingTime = dayjs(row[`closing_time_${weekday}`], 'HH:mm:ss').subtract(15, 'minutes');

      if (now.isBefore(openingTime) || now.isAfter(closingTime)) {
        isOpen = false;
      }
    }

    return (
      <ListItem
        key={id}
        modifier="longdivider"
        onClick={() => this.handleCafeClicked(row.id, isOpen && row.open)}
        className={preferredBean ? 'c-cafelist__item--highlight' : ''}
      >
        <div className="c-cafelist__item">
          <div>
            <p className="c-cafelist__item__name">{row.name} {preferredBean && <span className="cafelist__bean"> - {row.beans[0].name}</span>}</p>
            {isOpen ?
              (row.open ?
                null
                : <p className="c-cafelist__item__temporarily-closed">Sorry, we are temporarily not taking orders, we'll be back soon!</p>)
              : <p className="c-cafelist__item__closed">Closed</p>}
            <p className="c-cafelist__item__address">{row.address.replace(', New Zealand', '')}</p>
            <p className="c-cafelist__item__description">{row.description}</p>
          </div>

          <Image cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={row.logo} className="c-cafelist__item__image" />
        </div>
      </ListItem>
    );
  }

  render() {
    const { nearbySelected } = this.state;
    const { user, shops, userShops, position, positionAttempted, actions, searchTerm } = this.props;

    if (position) shops.sort((a, b) => relativeHaversineDistance(a.lat, a.lng, position.lat, position.lng) - relativeHaversineDistance(b.lat, b.lng, position.lat, position.lng));

    return (
      <>
        <div className="c-text__header home">
          <h1 className="">Hi {`${user.first_name}`}</h1>
          <h2>Where's good for you?</h2>
        </div>

        <div className="c-cafelist u-mb-1">
          {positionAttempted ?
            <>
              <div className="c-cafelist__header">
                <Button
                  className={`button--cta c-cafelist__header__button c-cafelist__header__button${nearbySelected ? '--selected' : '--not-selected'}`}
                  onClick={this.handleNearbyClicked}
                >
                  Nearby
                  {/* <Icon className="u-ml-1" size={15} icon={dropDownOpen ? "fa-chevron-up" : "fa-chevron-down"} color="white" /> */}
                </Button>
                <Button
                  className={`button--cta c-cafelist__header__button c-cafelist__header__button${!nearbySelected ? '--selected' : '--not-selected'}`}
                  onClick={this.handleRecentClicked}
                >
                  Recent
                </Button>
              </div>

              {/* <OrderTimePicker dropDownOpen={dropDownOpen} /> */}

              {nearbySelected ? (
                <>
                  <Input
                    className="u-mb-1"
                    placeholder="Search shops"
                    value={searchTerm}
                    type="search"
                    onChange={(e) => actions.setShopsSearchTerm(e.target.value)}
                  />
                  {shops.length > 0 ?
                    <List
                      dataSource={shops}
                      renderRow={this.renderRow}
                    />
                    : <p>Sorry, we couldn't find any shops for that search...</p>}
                </>
              ) : (
                <List
                  dataSource={userShops}
                  renderRow={this.renderRow}
                />
              )}
            </> :
            <Loading />}
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  return {
    userBeanIds: selectUserBeanIds(state),
    shops: selectShopsBySearchTerm(state),
    selectedShop: state.shops.selectedShop,
    searchTerm: state.shops.searchTerm,
    cartItems: state.user.cartItems,
    userShops: state.user.shops,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ShopActions, ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CafeList)