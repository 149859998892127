import React, { useState } from 'react';
import { Button, Input } from 'react-onsenui';
import Loading from '../Loading';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';


const cardStyle = {
  base: {
    fontSize: '16px',
    color: '#3a3a3a',
    backgroundColor: 'transparent',
    fontFamily: '"National-Book", sans-serif',
    letterSpacing: '0.025em',
    '::placeholder': {
      color: '#aab7c4',
    }
  },
  invalid: {
    color: '#c23d4b',
  }
};

function PaymentMethodsForm(props) {
  const { onSubmit, submitting, stripe, elements } = props;
  const [name, handleNameChanged] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);

  const cardElement = elements.getElement('cardNumber');

  const submit = () => {
    if (name === null || name === '') {
      return;
    }

    stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { name }
    }).then(paymentMethod => {
      onSubmit({ payment_method: paymentMethod });
    });
  }

  return (
    <div className="c-form">
      <h3 className="u-text-center u-mb-1">Add a new card</h3>

      <div className="u-mb-1">
        <label className="c-payment-methods__card-input-label">Card holder's name</label>

        <Input
          className="c-payment-methods__card-name-input u-mt-05"
          placeholder="John Doe"
          value={name}
          onChange={e => handleNameChanged(e.target.value)}
        />
      </div>

      <label className="c-payment-methods__card-input-label">Card number</label>

      <div className="c-payment-methods__card u-mb-1">
        <CardNumberElement
          hidePostalCode={true}
          style={cardStyle}
          onChange={e => {
            setCardComplete(e.complete)
          }}
        />
      </div>

      <div className="u-d-flex u-justify-content-between u-mb-1">
        <div className="u-d-flex u-d-flex-col u-width-100">
          <label className="c-payment-methods__card-input-label">Exp. Date</label>
          <div className="c-payment-methods__card c-payment-methods__card--inner u-mr-1">

            <CardExpiryElement
              style={cardStyle}
              onChange={e => {
                setExpiryComplete(e.complete)
              }}
            />
          </div>
        </div>

        <div className="u-d-flex u-d-flex-col u-width-100">
          <label className="c-payment-methods__card-input-label">CVC</label>
          <div className="c-payment-methods__card c-payment-methods__card--inner">
            <CardCVCElement
              style={cardStyle}
              placeholder='123'
              onChange={e => {
                setCvcComplete(e.complete)
              }}
            />
          </div>
        </div>
      </div>

      <div className="c-form__button-group">
        {submitting
          ? <Loading />
          : (
            <Button
              className="button--large--cta"
              onClick={submit}
              disabled={!name || !cardComplete || !expiryComplete || !cvcComplete}
            >
              Save
            </Button>
          )}
      </div>
    </div>
  )
}

export default injectStripe(PaymentMethodsForm);