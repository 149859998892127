import React from 'react';
import { Page } from 'react-onsenui';
import { connect } from 'react-redux';
import RenderToolbar from '../RenderToolbar';


function BuisnessForm(props) {
  const { popPage } = props;

  const renderToolbar = () => <RenderToolbar backAction={popPage}/>

  return (
    <Page renderToolbar={renderToolbar}>
      <h1>Business</h1>
    </Page>
  );
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(BuisnessForm);