import React, { Component } from 'react';
import { Page, Tabbar, Tab, Toolbar, Icon, Dialog, Button, ActionSheet } from 'react-onsenui';
import tabs from './tabs';
import Navigator from '../Navigator';

class TabBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      installMessageType: '',
      showNotificationPrompt: false,
      active: 1,
      tabStacks: [
        { size: 0 },
        { size: 0 },
        { size: 0 },
        { size: 0 }
      ],
    };
  }

  componentDidMount() {
    // check if mobile device
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = !isIos && userAgent.indexOf("android") > -1;

    // Detects if device is in standalone mode
    const isIosStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
    const isAndroidStandaloneMode = !isIos && window.matchMedia('(display-mode: standalone)').matches;

    // Checks if should display popup notification: 
    if (isIos) {
      if (isIosStandaloneMode) {
        if (window.Notification.permission === "default") {
          console.log('show notification prompt');
          this.setState({ showNotificationPrompt: true });
        }
      } else {
        console.log('show ios install message');
        this.setState({ installMessageType: 'ios' });
      }
    } else if (isAndroid && !isAndroidStandaloneMode) {
      console.log('show android install message');
      this.setState({ installMessageType: 'android' });
    }
  } 

  setActiveTab = tabIndex => {
    console.log('set active tab', tabIndex);
    
    this.setState({active: tabIndex});
  }

  renderToolbar = () => {
    const { active, tabStacks } = this.state;

    return tabStacks[active].size <= 1 && (
      <Toolbar position="top" className={active === 0 ? 'toolbar__dark' : 'toolbar__light'}>
        <div className="">
          <div className={`c-navbar__content c-navbar__content--${active}`}>
            {process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'staging' ?
              <h1 className="u-ml-1 u-text-black">Quicky Staging</h1>
              : <>
                <img alt="Quicky" className="c-navbar__logo u-mr-04" src={`/images/logo${active === 0 ? '_white' : ''}.png`} />
                <h1 className={`u-m-0 u-text-large${active > 0 ? ' u-text-black' : ''}`}>BETA</h1>
              </>}
          </div>
        </div>


        {/* <div className="right">
          <Button 
            className="cart-button" 
            modifier="material--flat"
            onClick={() => pushPage(Cart, 'cart')}
          >
            <svg version="1.1" id="svg__cup-with-steam" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 135 234.2">
              <g id="cup">
                <polygon className="st0" points="21.6,228.8 4.6,81.8 129.4,81.8 112.4,228.8"/>
                <path d="M128.9,82.3l-16.9,146H22L5.1,82.3H128.9 M130,81.3H4l17.1,148h91.8L130,81.3L130,81.3z"/>
              </g>
              <g id="steam">
                <path className="st1" d="M51.6,5.4c0,0-8.4,12.1-2.3,23.4c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"/>
                <path className="st1" d="M63,5.4c0,0-8.4,12.1-2.3,23.5c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"/>
                <path className="st1" d="M74.5,5.4c0,0-8.4,12.2-2.3,23.6c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.8-2,23.8"/>
              </g>
              <g id="liquid_quarter">
                <polygon className="st2" points="112.1,228.3 21.9,228.3 19.9,210.3 114.1,210.3"/>
              </g>
            </svg>
          </Button>
        </div>  */}

      </Toolbar>
    );
  }

  handleStackChange = (stackSize) => {
    const { active, tabStacks } = this.state;

    let newTabStacks = tabStacks;
    newTabStacks[active].size = stackSize;

    this.setState({ tabStacks: newTabStacks });

    console.log(this.state.tabStacks, stackSize);
  }

  renderTabs = (activeIndex, tabbar) => {
    return tabs.map((tab, i) => ({
      content: 
        <Navigator 
          tab={tab}
          key={`content${i}`}
          onStackChange={this.handleStackChange}
          swipeable={true}
          setActiveTab={this.setActiveTab}
        />,
      tab: 
        <Tab
          key={`tab${i}`}
        >{tab.iconPath ?
          <img src={tab.iconPath} />
          : tab.icon()
          }
        </Tab>
      })
    );
  }

  render() {
    const { installMessageType, showNotificationPrompt } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        <Dialog isOpen={showNotificationPrompt}>
          <div className="c-modal--notification-dialog">
            <Icon
              className="c-modal--notification-dialog__close"
              size={{default: 26, material: 26}}
              icon={{default: 'ion-ios-close', material: 'md-close'}}
              onClick={() => this.setState({showNotificationPrompt: false})}
            />

            <img src='/images/logo.png' className="c-modal--notification-dialog__allow-notifications" alt="App logo" />

            <p>Allow notifications from Quicky?</p>

            <div>
              <Button id="allow_notifications_btn" onClick={() => this.setState({showNotificationPrompt: false})}>Allow</Button>
              <Button className="u-ml-1" onClick={() => this.setState({showNotificationPrompt: false})}>Cancel</Button>
            </div>
          </div>
        </Dialog>

        <ActionSheet 
          isOpen={installMessageType !== ''} 
          isCancelable={true} 
          maskColor="rgba(0, 0, 0, 0.75)"
        >
          <div className="c-add-to-home">
            {/* <Icon
              className="c-add-to-home__close"
              size={{default: 26, material: 26}}
              icon={{default: 'ion-ios-close', material: 'md-close'}}
              onClick={() => this.setState({showInstallMessage: false})}
            /> */}

            <div className="u-d-flex u-align-items-center">
              <img src='/app-icon.jpg' className="c-add-to-home__logo" alt="App logo" />

              <h2>Install Quicky</h2>
            </div>

            <hr />

            <div className="c-add-to-home__content">
              {installMessageType === 'android' ? <>
                <p className="u-mb-1">To get started, follow the steps to install me as an app on your Home Screen.</p>

                <div className="u-d-flex u-align-items-center u-mb-1">
                  1. In the top right corner tap on
                  <img src='/ellipsis-vertical.svg' className="c-add-to-home__ellipsis" alt="Ellipsis vertical" />
                </div>

                <div className="u-d-flex u-align-items-center u-mb-1">
                  2. Then select
                  <img src='/install-menu-chrome.png' className="c-add-to-home__install-chrome" alt="Chrome install menu item" />
                </div>
              </>
                : installMessageType === 'ios' ? <>
                  <p className="u-mb-1">Using Safari as your browser, follow the steps to install me as an app on your Home Screen.</p>

                  <div className="u-d-flex u-align-items-center u-mb-1">
                    1. Tap on
                    <img src='/share-button-safari.png' className="c-add-to-home__share" alt="Share button" />
                  </div>

                  <div className="u-d-flex u-d-flex-col u-mb-1">
                    2. Then select
                    <img src='/add-to-home-safari.png' className="c-add-to-home__install-safari" alt="Safari install menu item" />
                  </div>
                </>
                  : null}
                  
                <p className="u-text-smaller">*For the best experience, make sure you accept the prompts for 'Location Services' and 'Notifications'.</p>
            </div>
          </div>
        </ActionSheet>

        <Tabbar
          position="bottom"
          index={this.state.active}
          renderTabs={this.renderTabs}
          onPreChange={() => this.setState({showToolbar: true})}
          onPostChange={e => this.setState({active: e.activeIndex})}
          swipeable={false}
        />
      </Page>
    );
  }
}

export default TabBar;