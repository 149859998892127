import React from 'react';
import DateTime from 'react-datepicker';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { subYears } from 'date-fns/esm';
import { Input, Radio, Select, Range, Col, Row, Checkbox, Button } from 'react-onsenui';

const renderTextField = ({ input, meta: { touched, error, warning }, ...custom }) => (
	<>
		<Input {...(touched ? { valid: !error, className: error ? 'is-invalid' : 'is-valid' } : {})} {...input} {...custom} />
		<br></br>
		{touched && error && <div className="c-form__error"><i>{error}</i></div>}
	</>
);

const renderPhoneField = ({ input, meta: { touched, error, warning }, ...custom }) => {
	const hasValue = input.value && input.value !== '';

	return <>
		<div className={`c-form__phone-input${hasValue ? ' u-bg--gray' : ''}`}>
			{hasValue ? <div className='c-form__phone-input-prefix'>+64</div> : null}

			<Input {...(touched ? { valid: !error, className: (error ? 'is-invalid' : 'is-valid') + (hasValue ? ' c-form__phone-input--active' : '') } : { className: hasValue && ' c-form__phone-input--active' })} {...input} {...custom} />
		</div>

		{touched && error && <div className="c-form__error"><i>{error}</i></div>}
	</>
};

const renderRadioField = ({ value, input, ...custom }) => (
  <Radio checked={value === input.value} {...input} {...custom} />
);

const renderRadioGroup = ({ input, meta: { touched, error }, options }) => (
	<>
		<div className={"btn-group btn-group-toggle" + (touched && error ? ' is-invalid' : '')} data-toggle="buttons">
			<Row>
				{options.map((option, i) => (
						<Col>
							<label className={input.value == option.value ? ` button check-button check-button--selected ${option.label}` : ` button check-button ${option.label}`} key={i}>
								{option.label}
								<Radio
									name={input.name} 
									value={option.value} 
									checked={input.value == option.value} 
									onClick={(e) => {
										console.log(input.value == option.value)
										return input.onChange(input.value == option.value ? null : parseInt(e.target.value))
									}}
									className="mr-2" 
								/>
							</label>
						</Col>
					)
				)}
			</Row>
		</div>
		{touched && error && <div className="invalid-feedback"><i>{error}</i></div>}
	</>
);

const renderCheckbox = ({ value, input, ...custom }) => (
  <Checkbox type="checkbox" checked={value === input.value} {...input} {...custom} />
);

const renderCheckboxToggle = ({ value, input, ...custom }) => {
	const checked = typeof input.value === 'boolean' ? input.value : (input.value === 'true');

	return (
		<Checkbox checked={checked} {...input} {...custom} />
	)
};

const renderSelectField = ({ input, meta: { touched, error }, options, placeholder, ...custom }) => (
	<>
		<Select {...(touched ? { valid: !error } : {})} {...input} {...custom}>
			{placeholder && <option value="" selected disabled>{placeholder}</option>}
			{options && options.length > 0 && options.map(option =>
					<option key={option.id} value={option.id}>{option.name}</option>    
			)}
		</Select>
		<br></br>
		{touched && error && <div className="c-form__error"><i>{error}</i></div>}
	</>
);

const renderDateTimeField = ({ input }) => (
	<DateTime 
		{...input}
		className="form-control"
		selected={input.value ? moment(input.value, 'dddd MMMM Do h:mma').toDate() : null}
		onChange={(date) => input.onChange(moment(date).format('dddd MMMM Do h:mma'))}
		showTimeSelect
		timeFormat="HH:mm"
		timeIntervals={5}
		dateFormat="dddd MMMM Do h:mma"
		timeCaption="time"
		autoComplete="off" 
	/>
);

const renderDateOfBirthField = ({ input }) => (
	<DateTime 
		{...input}
		className="form-control"
		selected={input.value ? moment(input.value, 'MM/DD/YYYY').toDate() : null}
		onChange={(date) => input.onChange(moment(date).format('MM/DD/YYYY'))}
		maxDate={new Date()}
		minDate={subYears(new Date(), 100)}
		peekNextMonth
		showMonthDropdown
		showYearDropdown
		dropdownMode="select"
		dateFormat="MM/DD/YYYY"
		autoComplete="off" />
);

const renderCheckboxGroup = ({input, meta, options, toggle}) => {
	const {name, onChange} = input;
	const {touched, error} = meta;
	const inputValue = input.value;

	const checkboxes = options.map(({name, id}, index) => {
		const handleChange = (event) => {
			const arr = [...inputValue];
			if (event.target.checked) {
				arr.push(id);
			}
			else {
				arr.splice(arr.indexOf(id), 1);
			}
			return onChange(arr);
		};
		
		const checked = inputValue.includes(id);

		return (
			<Col>
				<label className={checked ? `button check-button check-button--selected ${name}` : `button check-button ${name}`} key={id}>
					<span className="check-button__text">{name}</span>
					<Checkbox type="checkbox" name={`${input.name}[${index}]`} checked={checked} onChange={handleChange} />
				</label>
			</Col>
		);
	});

	const handleToggle = () => {
		if (inputValue.length === options.length) {
			return onChange([]);
		} else {
			return onChange(options.map(option => option.id));
		}
	}

	return (
		<div className="mb-4">
			<div className={"btn-group btn-group-toggle" + (touched && error ? ' is-invalid' : '')} data-toggle="buttons">
				<Row>
					{toggle ? (
						<button type="button" className={"btn btn-secondary" + ((inputValue.length === options.length) ? ' active' : '')} onClick={handleToggle}>Everyone</button>
					) : null}
					{checkboxes}
				</Row>
			</div>
			{touched && error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

const renderRange = ({input, options, meta: {touched, error}, ...custom}) => {
	input.value = input.value ? input.value * custom.step : custom.step;
	
	return (<>
			<Range 
				value={input.value}
				modifier={custom.modifier}
				step={custom.step}
				min={0}
				max={(options.length-1) * custom.step}
				onInput={(e) => input.onChange(e.target.value/custom.step)}
			/>

			{touched && error && <div className="invalid-feedback">{error}</div>}

			<p className="range__labels">
				{options.map((o, i) =>
					<span key={i}>{o.label}</span>)}
			</p>
		</>
	);
}

const renderIncrementorGroup = ({ input, options, ...custom }) => (
	<div className={"btn-group btn-group-toggle"} data-toggle="buttons">	
		{options.map((option, i) => {
			const valueCount = custom.values.filter(v => v === option.id).length;

			return (
				<label className={`button check-button ${option.name}`} key={i}>
					{option.name}
					
					<Radio className={`mr-2 incrementor-radio ${valueCount < 1 ? 'unselected' : 'selected'}`} disabled/>
							
					<span>{custom.name}</span> 
					<div className="c-incrementor">
						<Button disabled={valueCount < 1} onClick={() => custom.onDecrement(option.id)}><RemoveIcon/></Button>
						<span className="c-incrementor__count">{valueCount}</span>
						<Button className="c-incrementor__add" disabled={valueCount > 9} onClick={() => custom.onIncrement(option.id)}><AddIcon/></Button>
					</div>
				</label> 	
			);
		})}
	</div>
);

const renderCountriesDropdown = ({ input, meta: { touched, error } }) => (
	<>
		<Select type="select" {...(touched ? { valid: !error, className: error ? 'is-invalid' : 'is-valid' } : {})} {...input}>
			<option value="">Select a country...</option>
			<option value="New Zealand">New Zealand</option>
			<option value="Afghanistan">Afghanistan</option>
			<option value="Åland Islands">Åland Islands</option>
			<option value="Albania">Albania</option>
			<option value="Algeria">Algeria</option>
			<option value="American Samoa">American Samoa</option>
			<option value="Andorra">Andorra</option>
			<option value="Angola">Angola</option>
			<option value="Anguilla">Anguilla</option>
			<option value="Antarctica">Antarctica</option>
			<option value="Antigua and Barbuda">Antigua and Barbuda</option>
			<option value="Argentina">Argentina</option>
			<option value="Armenia">Armenia</option>
			<option value="Aruba">Aruba</option>
			<option value="Australia">Australia</option>
			<option value="Austria">Austria</option>
			<option value="Azerbaijan">Azerbaijan</option>
			<option value="Bahamas">Bahamas</option>
			<option value="Bahrain">Bahrain</option>
			<option value="Bangladesh">Bangladesh</option>
			<option value="Barbados">Barbados</option>
			<option value="Belarus">Belarus</option>
			<option value="Belgium">Belgium</option>
			<option value="Belize">Belize</option>
			<option value="Benin">Benin</option>
			<option value="Bermuda">Bermuda</option>
			<option value="Bhutan">Bhutan</option>
			<option value="Bolivia">Bolivia</option>
			<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
			<option value="Botswana">Botswana</option>
			<option value="Bouvet Island">Bouvet Island</option>
			<option value="Brazil">Brazil</option>
			<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
			<option value="Brunei Darussalam">Brunei Darussalam</option>
			<option value="Bulgaria">Bulgaria</option>
			<option value="Burkina Faso">Burkina Faso</option>
			<option value="Burundi">Burundi</option>
			<option value="Cambodia">Cambodia</option>
			<option value="Cameroon">Cameroon</option>
			<option value="Canada">Canada</option>
			<option value="Cape Verde">Cape Verde</option>
			<option value="Cayman Islands">Cayman Islands</option>
			<option value="Central African Republic">Central African Republic</option>
			<option value="Chad">Chad</option>
			<option value="Chile">Chile</option>
			<option value="China">China</option>
			<option value="Christmas Island">Christmas Island</option>
			<option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
			<option value="Colombia">Colombia</option>
			<option value="Comoros">Comoros</option>
			<option value="Congo">Congo</option>
			<option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
			<option value="Cook Islands">Cook Islands</option>
			<option value="Costa Rica">Costa Rica</option>
			<option value="Cote D'ivoire">Cote D'ivoire</option>
			<option value="Croatia">Croatia</option>
			<option value="Cuba">Cuba</option>
			<option value="Cyprus">Cyprus</option>
			<option value="Czech Republic">Czech Republic</option>
			<option value="Denmark">Denmark</option>
			<option value="Djibouti">Djibouti</option>
			<option value="Dominica">Dominica</option>
			<option value="Dominican Republic">Dominican Republic</option>
			<option value="Ecuador">Ecuador</option>
			<option value="Egypt">Egypt</option>
			<option value="El Salvador">El Salvador</option>
			<option value="Equatorial Guinea">Equatorial Guinea</option>
			<option value="Eritrea">Eritrea</option>
			<option value="Estonia">Estonia</option>
			<option value="Ethiopia">Ethiopia</option>
			<option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
			<option value="Faroe Islands">Faroe Islands</option>
			<option value="Fiji">Fiji</option>
			<option value="Finland">Finland</option>
			<option value="France">France</option>
			<option value="French Guiana">French Guiana</option>
			<option value="French Polynesia">French Polynesia</option>
			<option value="French Southern Territories">French Southern Territories</option>
			<option value="Gabon">Gabon</option>
			<option value="Gambia">Gambia</option>
			<option value="Georgia">Georgia</option>
			<option value="Germany">Germany</option>
			<option value="Ghana">Ghana</option>
			<option value="Gibraltar">Gibraltar</option>
			<option value="Greece">Greece</option>
			<option value="Greenland">Greenland</option>
			<option value="Grenada">Grenada</option>
			<option value="Guadeloupe">Guadeloupe</option>
			<option value="Guam">Guam</option>
			<option value="Guatemala">Guatemala</option>
			<option value="Guernsey">Guernsey</option>
			<option value="Guinea">Guinea</option>
			<option value="Guinea-bissau">Guinea-bissau</option>
			<option value="Guyana">Guyana</option>
			<option value="Haiti">Haiti</option>
			<option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
			<option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
			<option value="Honduras">Honduras</option>
			<option value="Hong Kong">Hong Kong</option>
			<option value="Hungary">Hungary</option>
			<option value="Iceland">Iceland</option>
			<option value="India">India</option>
			<option value="Indonesia">Indonesia</option>
			<option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
			<option value="Iraq">Iraq</option>
			<option value="Ireland">Ireland</option>
			<option value="Isle of Man">Isle of Man</option>
			<option value="Israel">Israel</option>
			<option value="Italy">Italy</option>
			<option value="Jamaica">Jamaica</option>
			<option value="Japan">Japan</option>
			<option value="Jersey">Jersey</option>
			<option value="Jordan">Jordan</option>
			<option value="Kazakhstan">Kazakhstan</option>
			<option value="Kenya">Kenya</option>
			<option value="Kiribati">Kiribati</option>
			<option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
			<option value="Korea, Republic of">Korea, Republic of</option>
			<option value="Kuwait">Kuwait</option>
			<option value="Kyrgyzstan">Kyrgyzstan</option>
			<option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
			<option value="Latvia">Latvia</option>
			<option value="Lebanon">Lebanon</option>
			<option value="Lesotho">Lesotho</option>
			<option value="Liberia">Liberia</option>
			<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
			<option value="Liechtenstein">Liechtenstein</option>
			<option value="Lithuania">Lithuania</option>
			<option value="Luxembourg">Luxembourg</option>
			<option value="Macao">Macao</option>
			<option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
			<option value="Madagascar">Madagascar</option>
			<option value="Malawi">Malawi</option>
			<option value="Malaysia">Malaysia</option>
			<option value="Maldives">Maldives</option>
			<option value="Mali">Mali</option>
			<option value="Malta">Malta</option>
			<option value="Marshall Islands">Marshall Islands</option>
			<option value="Martinique">Martinique</option>
			<option value="Mauritania">Mauritania</option>
			<option value="Mauritius">Mauritius</option>
			<option value="Mayotte">Mayotte</option>
			<option value="Mexico">Mexico</option>
			<option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
			<option value="Moldova, Republic of">Moldova, Republic of</option>
			<option value="Monaco">Monaco</option>
			<option value="Mongolia">Mongolia</option>
			<option value="Montenegro">Montenegro</option>
			<option value="Montserrat">Montserrat</option>
			<option value="Morocco">Morocco</option>
			<option value="Mozambique">Mozambique</option>
			<option value="Myanmar">Myanmar</option>
			<option value="Namibia">Namibia</option>
			<option value="Nauru">Nauru</option>
			<option value="Nepal">Nepal</option>
			<option value="Netherlands">Netherlands</option>
			<option value="Netherlands Antilles">Netherlands Antilles</option>
			<option value="New Caledonia">New Caledonia</option>
			<option value="New Zealand">New Zealand</option>
			<option value="Nicaragua">Nicaragua</option>
			<option value="Niger">Niger</option>
			<option value="Nigeria">Nigeria</option>
			<option value="Niue">Niue</option>
			<option value="Norfolk Island">Norfolk Island</option>
			<option value="Northern Mariana Islands">Northern Mariana Islands</option>
			<option value="Norway">Norway</option>
			<option value="Oman">Oman</option>
			<option value="Pakistan">Pakistan</option>
			<option value="Palau">Palau</option>
			<option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
			<option value="Panama">Panama</option>
			<option value="Papua New Guinea">Papua New Guinea</option>
			<option value="Paraguay">Paraguay</option>
			<option value="Peru">Peru</option>
			<option value="Philippines">Philippines</option>
			<option value="Pitcairn">Pitcairn</option>
			<option value="Poland">Poland</option>
			<option value="Portugal">Portugal</option>
			<option value="Puerto Rico">Puerto Rico</option>
			<option value="Qatar">Qatar</option>
			<option value="Reunion">Reunion</option>
			<option value="Romania">Romania</option>
			<option value="Russian Federation">Russian Federation</option>
			<option value="Rwanda">Rwanda</option>
			<option value="Saint Helena">Saint Helena</option>
			<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
			<option value="Saint Lucia">Saint Lucia</option>
			<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
			<option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
			<option value="Samoa">Samoa</option>
			<option value="San Marino">San Marino</option>
			<option value="Sao Tome and Principe">Sao Tome and Principe</option>
			<option value="Saudi Arabia">Saudi Arabia</option>
			<option value="Senegal">Senegal</option>
			<option value="Serbia">Serbia</option>
			<option value="Seychelles">Seychelles</option>
			<option value="Sierra Leone">Sierra Leone</option>
			<option value="Singapore">Singapore</option>
			<option value="Slovakia">Slovakia</option>
			<option value="Slovenia">Slovenia</option>
			<option value="Solomon Islands">Solomon Islands</option>
			<option value="Somalia">Somalia</option>
			<option value="South Africa">South Africa</option>
			<option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
			<option value="Spain">Spain</option>
			<option value="Sri Lanka">Sri Lanka</option>
			<option value="Sudan">Sudan</option>
			<option value="Suriname">Suriname</option>
			<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
			<option value="Swaziland">Swaziland</option>
			<option value="Sweden">Sweden</option>
			<option value="Switzerland">Switzerland</option>
			<option value="Syrian Arab Republic">Syrian Arab Republic</option>
			<option value="Taiwan, Province of China">Taiwan, Province of China</option>
			<option value="Tajikistan">Tajikistan</option>
			<option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
			<option value="Thailand">Thailand</option>
			<option value="Timor-leste">Timor-leste</option>
			<option value="Togo">Togo</option>
			<option value="Tokelau">Tokelau</option>
			<option value="Tonga">Tonga</option>
			<option value="Trinidad and Tobago">Trinidad and Tobago</option>
			<option value="Tunisia">Tunisia</option>
			<option value="Turkey">Turkey</option>
			<option value="Turkmenistan">Turkmenistan</option>
			<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
			<option value="Tuvalu">Tuvalu</option>
			<option value="Uganda">Uganda</option>
			<option value="Ukraine">Ukraine</option>
			<option value="United Arab Emirates">United Arab Emirates</option>
			<option value="United Kingdom">United Kingdom</option>
			<option value="United States">United States</option>
			<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
			<option value="Uruguay">Uruguay</option>
			<option value="Uzbekistan">Uzbekistan</option>
			<option value="Vanuatu">Vanuatu</option>
			<option value="Venezuela">Venezuela</option>
			<option value="Viet Nam">Viet Nam</option>
			<option value="Virgin Islands, British">Virgin Islands, British</option>
			<option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
			<option value="Wallis and Futuna">Wallis and Futuna</option>
			<option value="Western Sahara">Western Sahara</option>
			<option value="Yemen">Yemen</option>
			<option value="Zambia">Zambia</option>
			<option value="Zimbabwe">Zimbabwe</option>
		</Select>
		{touched && error && <div className="c-form__error"><i>{error}</i></div>}
	</>
);

export const renderField = props => {
    switch(props.type) {
        case 'radio': 
            return renderRadioField(props);
        case 'radioGroup':
            return renderRadioGroup(props);
        case 'checkbox':
            return renderCheckbox(props);
        case 'checkboxToggle':
            return renderCheckboxToggle(props);
        case 'checkboxGroup':
            return renderCheckboxGroup(props);
        case 'select':
            return renderSelectField(props);
        case 'datetime':
            return renderDateTimeField(props);
        case 'dateofbirth':
            return renderDateOfBirthField(props);
        case 'country': 
            return renderCountriesDropdown(props);
				case 'range':
						return renderRange(props)
				case 'incrementorGroup':
						return renderIncrementorGroup(props);
				case 'phone':
					return renderPhoneField(props);
        case 'text':
        case 'textarea':
				case 'number':
        default:
            return renderTextField(props);
    }
};