import { GET_COMPANY, UPDATE_ORDER_STATUS } from '../actionTypes';

const initialState = {};

const reducer = (state = initialState, { type, payload = null}) => {
  switch(type) {
    case GET_COMPANY:
      return getCompany(state, payload);
    case UPDATE_ORDER_STATUS:
      return updateOrderStatus(state, payload);
    default:
      return state;
  }
}

function getCompany(state, payload) {
  return {
    ...state, ...payload.data
  }
}

function updateOrderStatus(state, payload) {
  return {
    ...state,
    orders: state.orders.map(order => {
      if(order.id === payload.data.id) {
        return {
          ...order,
          order_status_id: payload.data.order_status_id,
          order_status: payload.data.order_status,
        }
      }

      return order;
    })
  }
}

export default reducer;