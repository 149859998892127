import axios from 'axios';

const api = axios.create();


const hostname = process.env.REACT_APP_API;
const protocol = hostname.includes('local') || hostname === '127.0.0.1:8000' ? 'http://' : 'https://';

api.defaults.baseURL = `${protocol}${hostname}/api/`;
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    api.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

api.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem('access_token');

        config.headers.common['Authorization'] = 'Bearer ' + token;

        return config;
    }, 
    function(error) {
        console.log(error);
        return Promise.reject(error);
    });

export default api;