import * as api from '../api/api';
import ajaxCallError from './ajaxCallError';
import { 
  GET_SHOPS,
  SET_SHOPS_SEARCH_TERM,
  SET_SHOPS_SELECTED_SHOP,
  SET_SHOPS_SELECTED_PRODUCT,
  ADD_USER_SHOP,
  REMOVE_USER_SHOP,
  GET_DISCOUNT_BY_CODE
} from '../actionTypes';

export function getShops() {
  return function(dispatch) {
    return api
      .getShops()
      .then(
        response => dispatch(getShopsSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getShopsSuccess(response) {
  return {
    type: GET_SHOPS,
    payload: response.data || {}
  }
}

export function addUserShop(shop_id) {
  return function(dispatch) {
      return api
          .addUserShop(shop_id)
          .then(
              response => dispatch(addUserShopSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function addUserShopSuccess(response) {
  return {
      type: ADD_USER_SHOP,
      payload: response.data || {}
  }
}

export function removeUserShop(id) {
  return function(dispatch) {
      return api
          .removeUserShop(id)
          .then(
              response => dispatch(removeUserShopSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function removeUserShopSuccess(response) {
  return {
      type: REMOVE_USER_SHOP,
      payload: response.data || {}
  }
}

export function setShopsSearchTerm(searchTerm) {
  return {
    type: SET_SHOPS_SEARCH_TERM,
    payload: searchTerm
  }
}

export function setSelectedShop(id) {
  return {
    type: SET_SHOPS_SELECTED_SHOP,
    payload: id
  }
}

export function setSelectedProduct(id) {
  return {
    type: SET_SHOPS_SELECTED_PRODUCT,
    payload: id
  }
}

export function getDiscountByCode(code) {
  return function(dispatch) {
    return api
      .getDiscountByCode(code)
      .then(
        response => dispatch(getDiscountByCodeSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getDiscountByCodeSuccess(response) {
  return {
    type: GET_DISCOUNT_BY_CODE,
    payload: response.data || {}
  }
}