import React from 'react';
import { Field } from 'redux-form';
import { renderField } from '../../RenderField';
import { Range } from 'react-onsenui';



export function DrinkExtrasStep(props) {
  const { incrementableExtras, nonIncrementableExtras, beanVariations, milkVariations, onExtrasIncrement, onExtrasDecrement, extraVals, strengthVariations } = props;

  const sortStrengthOptions = (a, b) => {
    if (b.name === 'Single') return 1;
    if (b.name === 'Double') return a.name === 'Single' ? -1 : 1;
    if (b.name === 'Triple') return a.name === 'Double' ? -1 : 1;
    if (b.name === 'Quad') return -1;

    return 0;
  }

  return (
    <>
      <div className="c-drink__extras">

      {strengthVariations.length > 0 && (
        <div className="u-mb-2 c-drink__extras-strength">
          <h3>Strength</h3>
          <Field 
            id="strength"
            name="strength"
            value={1}
            type="range"
            modifier="material"
            step={50}
            options={strengthVariations.sort(sortStrengthOptions).map(v => ({label: `${v.name.charAt(0).toUpperCase()}${v.name.slice(1)}`, value: v.id}))}
            component={renderField}
          />
        </div>)}

        {beanVariations.length > 0 && (
          <div className="u-mb-2 c-drink__extras-beans">
            <h3 className="u-text-center">This cafe serves</h3>
            
            <Field 
              id="beans"
              name="beans"
              type="radioGroup"
              options={beanVariations.map(v => ({label: `${v.name.charAt(0).toUpperCase()}${v.name.slice(1)}`, value: v.id}))}
              component={renderField}
            />
          </div>
        )}

        {milkVariations.length > 0 && (
          <div className="u-mb-2 c-drink__extras-milk">
            <h3>Choose Milk</h3>
            <Field 
              id="milk"
              name="milk"
              type="radioGroup"
              options={milkVariations.map(v => ({label: `${v.name.charAt(0).toUpperCase()}${v.name.slice(1)}`, value: v.id}))}
              component={renderField}
            />
          </div>
        )}

        {incrementableExtras && incrementableExtras.length > 0 && (
          <div className="u-mb-2 c-drink__extras-other incrementable_extras">
            <h3>Sweeteners</h3>
            <Field 
              id="incrementable_extras"
              name="incrementable_extras"
              type="incrementorGroup"
              values={extraVals}
              options={incrementableExtras.map(e => ({name: `${e.name.charAt(0).toUpperCase()}${e.name.slice(1)}`, id: e.id}))}
              onIncrement={onExtrasIncrement}
              onDecrement={onExtrasDecrement}
              component={renderField}
            />
          </div>
        )}

        {/* {nonIncrementableExtras && nonIncrementableExtras.length > 0 && (
          <div className="u-mb-2 c-drink__extras-other">
            <Field 
              id="extras"
              name="extras"
              type="checkboxGroup"
              options={nonIncrementableExtras.map(e => ({name: `${e.name.charAt(0).toUpperCase()}${e.name.slice(1)}`, id: e.id}))}
              component={renderField}
            />
          </div>
        )} */}

        {nonIncrementableExtras && Object.keys(nonIncrementableExtras).length > 0 ? 
          Object.keys(nonIncrementableExtras).map(typeID => (
            <div className="u-mb-2 c-drink__extras-other">
              <h3>{nonIncrementableExtras[typeID].type.name}</h3>

              <Field 
                id="extras"
                name="extras"
                type="checkboxGroup"
                options={nonIncrementableExtras[typeID].extras}
                component={renderField}
              />
            </div>
          ))
        :null}
      </div>
    </>
  );
}