import { 
  AUTH_CHECK, 
  GET_AUTH_USER, 
  GET_USER,
  SAVE_USER,
  LOGIN, 
  LOGOUT, 
  ADD_USER_SHOP, 
  ADD_CART_ITEM, 
  GET_CART_ITEMS, 
  CLEAR_CART_ITEMS, 
  UPDATE_CART_ITEM, 
  REMOVE_USER_SHOP, 
  PURCHASE, 
  UPDATE_CART_ITEM_VARIATION, 
  UPDATE_CART_ITEM_EXTRAS, 
  REMOVE_CART_ITEM,
  UPDATE_USER_BEAN_PREFERENCES,
  UPDATE_TILEVIEW_PREFERRED,
  UPDATE_USER_ORDER_STATUS,
  ADD_USER_PAYMENT_METHOD,
  GET_USER_PAYMENT_METHODS,
  DESTROY_USER_PAYMENT_METHOD,
  CANCEL_ORDER,
  UPDATE_PICKUP_TIME,
  UPDATE_ORDER_PICKUP_TIME,
  DELAY_ORDER_PICKUP_TIME,
  UPDATE_CART_ITEM_NOTES,
  UPDATE_USER_DEFAULT_PAYMENT_METHOD
} from '../actionTypes';
import { createSelector } from 'reselect';
import * as dayjs from 'dayjs';
import sort from '../utils/sort';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const initialState = {
  id: null,
  loggedIn: false,
  confirmed: false,
  first_name: '',
  last_name: '',
  email: '',
  admin: false,
  token: '',
  cartItems: [],
  pickupTime: null,
  orders: [],
  paymentMethods: [],
  default_payment_method_id: null
}

const reducer = (state = initialState, { type, payload = null}) => {
  switch(type) {
    case AUTH_CHECK:
      return checkAuth(state, payload);
    case GET_USER: 
      return getUser(state, payload);
    case GET_AUTH_USER:
      return getAuthUser(state, payload);
    case SAVE_USER: 
      return saveUser(state, payload);
    case LOGIN:
      return login(state, payload);
    case LOGOUT:
      return logout(state);
    case ADD_USER_SHOP:
      return addUserShop(state, payload);
    case REMOVE_USER_SHOP:
      return removeUserShop(state, payload);
    case ADD_CART_ITEM:
      return addCartItem(state, payload);
    case GET_CART_ITEMS:
      return getCartItems(state, payload);
    case CLEAR_CART_ITEMS:
      return clearCartItems(state, payload);
    case UPDATE_CART_ITEM:
      return updateCartItem(state, payload);
    case UPDATE_CART_ITEM_VARIATION:
      return updateCartItemVariation(state, payload);
    case UPDATE_CART_ITEM_EXTRAS:
      return updateCartItemExtras(state, payload);
    case UPDATE_CART_ITEM_NOTES:
      return updateCartItemNotes(state, payload);
    case UPDATE_PICKUP_TIME:
      return updatePickupTime(state, payload);
    case UPDATE_ORDER_PICKUP_TIME:
      return updateOrderPickupTime(state, payload);
    case DELAY_ORDER_PICKUP_TIME:
      return delayOrderPickupTime(state, payload);
    case REMOVE_CART_ITEM: 
      return removeCartItem(state, payload);
    case PURCHASE:
      return purchase(state, payload);
    case UPDATE_USER_BEAN_PREFERENCES:
      return updateUserBeanPreferences(state, payload);
    case UPDATE_TILEVIEW_PREFERRED:
      return updateTileviewPreferred(state, payload);
    case UPDATE_USER_ORDER_STATUS:
      return updateUserOrderStatus(state, payload);
    case ADD_USER_PAYMENT_METHOD:
      return addUserPaymentMethod(state, payload);
    case GET_USER_PAYMENT_METHODS:
      return getUserPaymentMethods(state, payload);
    case DESTROY_USER_PAYMENT_METHOD:
      return destroyUserPaymentMethod(state, payload);
    case UPDATE_USER_DEFAULT_PAYMENT_METHOD:
      return updateUserDefaultPaymentMethod(state, payload);
    case CANCEL_ORDER:
      return cancelOrder(state, payload);
    default:
      return state;
  }
}

function checkAuth(state, payload) {
  state = Object.assign({}, state, {
    loggedIn: payload
  });

  return state;
}

function getAuthUser(state, payload) {
  return {
    ...state, 
    ...payload,
    admin: payload.admin,
    loggedIn: true,
  }
}

function getUser(state, payload) {
  return {
    ...state, 
    ...payload,
    loggedIn: true,
  }
}

function saveUser(state, payload) {
  return {
    ...state, ...payload.data
  }
}

function login(state, payload) {
  localStorage.setItem('access_token', payload.data.access_token);
  
  return {
    ...state,
    token: payload.data.access_token, 
  }
}

function logout(state) {
  localStorage.removeItem('access_token');

  return initialState;
}

function addUserShop(state, payload) {
  return {
    ...state,
    shops: [
      ...state.shops,
      payload.data
    ]
  }
}

function removeUserShop(state, payload) {
  return {
    ...state,
    shops: state.shops.filter(shop => shop.id !== payload.data.id)
  }
}

function addCartItem(state, payload) {
  const c = [
    ...state.cartItems,
    payload
  ];

  localStorage.setItem('cartItems', JSON.stringify(c));

  return {
    ...state,
    cartItems: c
  };
}

function getCartItems(state, payload) {
  return {
    ...state,
    cartItems: payload
  }
}

function clearCartItems(state, payload) {
  localStorage.setItem('cartItems', JSON.stringify([]));

  return {
    ...state,
    cartItems: payload
  }
}

// function updateCartItem(state, payload) {

//   const c = {
//     ...state.cartItems,
//     [payload.product_id]: {
//       ...state.cartItems[payload.product_id],
//       number: payload.number
//     }
//   };
  
//   localStorage.setItem('cartItems', JSON.stringify(c));

//   return {
//     ...state,
//     cartItems: c
//   }
// }

function updateCartItem(state, payload) {

  let c = [
    ...state.cartItems.map((item, i) => {
      if (i === payload.index) {
        return {
          ...item,
          number: payload.number
        }
      }

      return item;
    })
  ];

  console.log('reducer', payload, c)
  
  localStorage.setItem('cartItems', JSON.stringify(c));

  return {
    ...state,
    cartItems: c
  }
}


function updateCartItemVariation(state, payload) {
  let c = [
    ...state.cartItems.map((item, i) => {
      if(i === payload.index) {
        return {
          ...item,
          variations: {
            ...item.variations,
            [payload.typeId]: payload.variationId
          }
        }
      }

      return item;
    })
  ];
  
  localStorage.setItem('cartItems', JSON.stringify(c));

  return {
    ...state,
    cartItems: c
  };
}

function updateCartItemExtras(state, payload) {
  let c = [
    ...state.cartItems.map((item, i) => {
      if(i === payload.index) {
        return {
          ...item,
          extras: payload.extras,
        };
      }

      return item;
    })
  ];

  localStorage.setItem('cartItems', JSON.stringify(c));

  return {
    ...state,
    cartItems: c
  };
}

function updateCartItemNotes(state, payload) {
  let c = [
    ...state.cartItems.map((item, i) => {
      if(i === payload.index) {
        return {
          ...item,
          notes: payload.notes,
        };
      }

      return item;
    })
  ];

  localStorage.setItem('cartItems', JSON.stringify(c));

  return {
    ...state,
    cartItems: c
  };
}

function updatePickupTime(state, payload) {
  localStorage.setItem('pickupTime', JSON.stringify(payload.pickupTime));

  return {
    ...state,
    pickupTime: payload.pickupTime
  };
}

function updateOrderPickupTime(state, payload) {
  let newOrder = {...state.orders.find(o => o.id === payload.data.id)};
  newOrder.pickup_time = payload.data.pickup_time;
  let newOrders = state.orders.map(o => o.id === newOrder.id ? newOrder : o);
  
  return {
    ...state,
    orders: newOrders
  }
}

function delayOrderPickupTime(state, payload) {
  let newOrder = {...state.orders.find(o => o.id === payload.id)};
  newOrder.pickup_time = payload.pickup_time;
  let newOrders = state.orders.map(o => o.id === newOrder.id ? newOrder : o);
  
  return {
    ...state,
    orders: newOrders
  }
}

function removeCartItem(state, payload) {
  let c = [
    ...state.cartItems.filter((item, i) => i !== payload.index)
  ];

  localStorage.setItem('cartItems', JSON.stringify(c));

  return {
    ...state,
    cartItems: c
  };
}

function purchase(state, payload) {
  if (!payload.errors) {
    return {
      ...state,
      pickupTime: null,
      orders: state.orders.concat(payload.data),
      shops: state.shops.map(shop => {
        let orderShop = payload.data.find(order => order.shop_id === shop.id);
  
        if (orderShop) {
          let shopProducts = orderShop.products.filter(product => product.shop_id === shop.id);
  
          if (shopProducts.length) {
            return {
              ...shop,
              categories: shop.categories.map(cat => {
                return {
                  ...cat,
                  products: cat.products.map(product => {
                    let newProduct = shopProducts.find(shopProduct => shopProduct.product_id === product.id);
        
                    if (newProduct) {
                      return newProduct.product;
                    }
  
                    return product;
                  })
                }
              })
            }
          }
          
          return shop;
        }
  
        return shop;
      })
    }
  }

  return state;
}

function updateUserBeanPreferences(state, payload) {
  return {
    ...state,
    beans: payload.data
  }
}

function updateTileviewPreferred(state, payload) {
  return {
    ...state,
    tileview_preferred: payload.data
  }
}

function updateUserOrderStatus(state, payload) {
  let newOrder = {...state.orders.find(o => o.id === payload.orderId)};
  newOrder.order_status_id = payload.statusId;

  let newOrders = state.orders.map(o => o.id === newOrder.id ? newOrder : o);

  console.log('new orders', newOrders)
  
  return {
    ...state,
    orders: newOrders
  }
}

function addUserPaymentMethod(state, payload) {
  return {
    ...state,
    paymentMethods: [...state.paymentMethods, payload.data]
  }
}

function updateUserDefaultPaymentMethod(state, payload) {
  return {
    ...state,
    default_payment_method_id: payload.data
  }
}

function getUserPaymentMethods(state, payload) {
  return {
    ...state,
    paymentMethods: payload.data
  }
}

function destroyUserPaymentMethod(state, payload) {
  let newPaymentMethods = [...state.paymentMethods]
  newPaymentMethods = newPaymentMethods.filter(p => p.id !== payload.data.id);

  return {
    ...state,
    paymentMethods: newPaymentMethods
  }
}

function cancelOrder(state, payload) {
  return {
    ...state,
    orders: state.orders.filter(o => o.id !== payload.data.id)
  };
}

export const selectUser = state => state.user;

export const selectUserPaymentMethods = createSelector([selectUser], user => {
  if (user.paymentMethods && user.paymentMethods.length) {
    return user.paymentMethods;
  }
});

export const selectUserBeanIds = createSelector([selectUser], user => {
  return user.beans ? user.beans.map(b => b.id) : [];
});

export const selectUserOrders = createSelector([selectUser], user => {
  return user.orders;
});

export const selectCurrentOrders = createSelector([selectUserOrders], orders => {
  if (orders.length > 0) {
    const unprocessedOrders = orders.filter(order => order.order_status_id < 5);
    
    if (unprocessedOrders.length > 0) {
      let results = unprocessedOrders.filter(order => {
        const dtNow = dayjs();        
        const dtOrder = dayjs.utc(order.pickup_time);
        const diff = dayjs.duration(dtNow.diff(dtOrder)).asHours();

        return diff < 2;
      });

      if (results.length > 0) {
        return results.sort(sort.createdAtAcending);
      }
    }
  }

  return null;
});

export default reducer;