import React, { Component } from 'react';
import { Select, Row, Col, Card, Button } from 'react-onsenui';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Label } from 'reactstrap';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc)
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

class OrderTimePicker extends Component {

  constructor(props) {
    super(props);

    this.interval = null;
    this.now = dayjs();
    this.asap = this.now.add(10, 'minutes');
    let pickupVariation = 'asap';
    let initialTime = this.asap;

    if(this.props.initialTime){
      const time = dayjs(this.props.initialTime, dateFormat);
      
      if(time.isAfter(this.asap)){
        initialTime = time;
        pickupVariation = 'choose';
      }
    }

    this.state = {
      pickupVariation: pickupVariation,
      pickupTime: initialTime,
      chooseATime: false
    }
  }

  componentDidMount() {
    this.props.onTimeChanged(this.state.pickupTime.utc().format(dateFormat));

    this.interval = setInterval(() => {
      const now = dayjs();

      if(now.isAfter(this.state.pickupTime.subtract(10, 'minutes'))){
        this.now = now;
        this.asap = now.add(10, 'minutes');
        this.handleOptionSelected('asap');
      }
    }, 60000);
  }

  componentWillUnmount() {
    if(this.interval){
      console.log('clearing interval');
      clearInterval(this.interval);
    }
  }

  handleOptionSelected = (option) => {
    const newState = { pickupVariation: option };

    if (option !== 'choose' && option !== 'asap') {
      const minutes = parseInt(option);
      newState.pickupTime = this.now.add(minutes, 'minutes');
      this.props.onTimeChanged(newState.pickupTime.utc().format(dateFormat));
    } else if (option === 'asap') {
      newState.pickupTime = this.asap;
      this.props.onTimeChanged(newState.pickupTime.utc().format(dateFormat));
    } else {
      newState.chooseATime = true;
    }

    this.setState({ ...newState });
  }

  handleCustomTimeSelected = (customTime) => {
    this.setState({ pickupTime: customTime });
    this.props.onTimeChanged(customTime.utc().format(dateFormat));
  }

  render() {
    const { pickupTime, chooseATime, pickupVariation } = this.state;
    const { closingTime } = this.props;

    return (
      <Row className='u-align-items-center'>
        <Col width='100%'>
          <Label>When do you want to pick up?</Label>
        </Col>

        <Col width='100%'>
          <Select
            value={pickupVariation}
            onChange={(event) => this.handleOptionSelected(event.target.value)}
            className='u-mb-1'
          >
            <option value="asap">ASAP</option>
            <option value="15">in 15 minutes</option>
            <option value="20">in 20 minutes</option>
            <option value="25">in 25 minutes</option>
            <option value="30">in 30 minutes</option>
            <option value="choose">Custom time</option>
          </Select>
        </Col>

        <Col width='100%'>
          Pick up at: <Button className='u-ml-05' onClick={() => this.handleOptionSelected('choose')} ripple={true}>{pickupTime.format('h:mma')}</Button>
        </Col>

        {chooseATime && <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            open={chooseATime}
            value={pickupTime}
            onChange={(customTime) => this.handleCustomTimeSelected(customTime)}
            minutesStep={5}
            minTime={this.asap}
            maxTime={closingTime ? dayjs(closingTime, 'HH:mm:ss') : this.now.hour(17).minute(0)}
            renderInput={(params) => <div {...params} />}
            onAccept={() => this.setState({ chooseATime: false })}
            onClose={() => this.setState({ chooseATime: false })}
            ampmInClock={true}
          />
        </LocalizationProvider>}
      </Row>
    );
  }
}

export default OrderTimePicker