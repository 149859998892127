import apiClient from "./apiClient";

// User
export function getAuthUser() {
  return apiClient.get(`/auth/user`);
}

export function getUser() {
  return apiClient.get(`/auth/user`);
}

export function saveUser(obj) {
  return apiClient.post('/user', obj);
}

export function login(obj) {
  return apiClient.post(`/auth/login`, obj);
}

export function signup(obj) {
  return apiClient.post(`/auth/signup`, obj);
}

export function createPasswordReset(obj) {
  return apiClient.post(`/password/create`, obj);
}

export function findPasswordReset(token) {
  return apiClient.get(`/password/find/${token}`);
}

export function passwordReset(obj) {
  return apiClient.post(`/password/reset`, obj);
}

export function addUserShop(shop_id) {
  return apiClient.post(`/user/shop/${shop_id}`, {});
}

export function removeUserShop(id) {
  return apiClient.delete(`/user/shop/${id}`);
}

export function purchase(obj) {
  return apiClient.post('/user/purchase', obj);
}

export function updateUserBeanPreferences(obj, id) {
  return apiClient.post(`/user/${id}/bean_preferences`, obj);
}

export function updateTileviewPreferred(id) {
  return apiClient.put(`/user/${id}/update_tileview_preferred`);
}

export function addUserPaymentMethod(id, obj) {
  return apiClient.post(`/user/${id}/payment_methods`, obj)
}

export function updateUserDefaultPaymentMethod(id, obj) {
  return apiClient.post(`/user/${id}/default_payment_method`, obj)
}

export function getUserPaymentMethods(id) {
  return apiClient.get(`/user/${id}/payment_methods`);
}

export function destroyUserPaymentMethod(obj) {
  return apiClient.post(`/user/payment_methods/delete`, obj);
}

export function sendHelpEmail(id, obj) {
  return apiClient.post(`/user/${id}/send_help_email`, obj);
}

export function submitFeedback(id, obj) {
  return apiClient.post(`/user/${id}/submit_feedback`, obj);
}

export function getOrder(id) {
  return apiClient.get(`/order/${id}`);
}

export function redeemOrderProduct(id) {
  return apiClient.post(`/orderProduct/${id}/redeem`);
}

export function updateOrderStatus(id, obj) {
  return apiClient.post(`/order/${id}/status`, obj);
}

export function updateOrderPickupTime(id, obj) {
  return apiClient.post(`/order/${id}/pickup-time`, obj);
}

export function getShops() {
  return apiClient.get('/shops');
}

export function getBeans() {
  return apiClient.get('/beans');
}

export function getCompany(id) { 
  return apiClient.get(`/company/${id}`);
}

export function storePushSubscription(obj) {
  return apiClient.post('/push', obj);
}

export function signupActivate(token) {
  return apiClient.get(`/auth/signup/activate/${token}`);
}

export function cancelOrder(id) {
  return apiClient.delete(`/order/${id}`);
}

export function getDiscountByCode(code) {
  return apiClient.get(`/discount/${code}`);
}
