import React from 'react';
import { Toolbar, BackButton, Button } from 'react-onsenui';

export default function RenderToolbar(props) {
  const { backAction } = props;

  return (
    <Toolbar>
      {backAction && (
        <div className="left u-width-fit">
          <BackButton onClick={backAction}>Back</BackButton>
        </div>
      )}
      <div className="center">
        <div className="c-navbar__content">
          {process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'staging' ?
            <h1>Quicky Staging</h1>
            : <>
              <img alt="Quicky" className="c-navbar__logo u-mr-025" src="/images/logo.png" />
              <h1 className="u-m-0 u-text-large u-text-strong u-text-black">BETA</h1>
            </>}
        </div>
      </div>

      {/* <div className="right">
        <Button 
          className="cart-button" 
          modifier="material--flat"
          // onClick={() => pushPage(Cart, 'cart')}
        >
          <svg version="1.1" id="svg__cup-with-steam" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 135 234.2">
            <g id="cup">
              <polygon className="st0" points="21.6,228.8 4.6,81.8 129.4,81.8 112.4,228.8"/>
              <path d="M128.9,82.3l-16.9,146H22L5.1,82.3H128.9 M130,81.3H4l17.1,148h91.8L130,81.3L130,81.3z"/>
            </g>
            <g id="steam">
              <path className="st1" d="M51.6,5.4c0,0-8.4,12.1-2.3,23.4c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"/>
              <path className="st1" d="M63,5.4c0,0-8.4,12.1-2.3,23.5c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"/>
              <path className="st1" d="M74.5,5.4c0,0-8.4,12.2-2.3,23.6c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.8-2,23.8"/>
            </g>
            <g id="liquid_quarter">
              <polygon className="st2" points="112.1,228.3 21.9,228.3 19.9,210.3 114.1,210.3"/>
            </g>
          </svg>
        </Button>
      </div>  */}
    </Toolbar>

  )
}