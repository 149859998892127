import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import user from './user';
import company from './company';
import shops from './shops';
import beans from './beans';
import { reducer as toastrReducer } from 'react-redux-toastr';

export default combineReducers({
  user,
  company,
  shops,
  beans,
  form: formReducer,
  toastr: toastrReducer,
});