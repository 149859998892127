import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LOGIN, AJAX_CALL_ERROR } from '../../actionTypes';
import * as UserActions from '../../actions/user';
import * as BeanActions from '../../actions/bean';
import LoginForm from './LoginForm';
import Register from '../register/Register';
import { SubmissionError } from 'redux-form';
import { Page, Toolbar, Button, Card, Icon } from 'react-onsenui';
import ResetPassword from './ResetPassword';
import RenderToolbar from '../RenderToolbar';
import ServiceWorker from '../../serviceWorker';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginFailed: false,
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getBeans().then(response => console.log(response));
  }

  handleSubmit = values => {
    const { actions } = this.props;

    return actions.login({
      email: values.email,
      password: values.password
    }).then(response => {
      console.log(response);

      if (response.type === LOGIN && response.payload.message !== 'Authentication Failed') {
        actions.getUser().then(response => {
          console.log(response);
        });

        new ServiceWorker().init();
      } else if (response.type === AJAX_CALL_ERROR) {
        throw new SubmissionError({
          _error: response.payload.response.data.message
        });
      } else {
        this.setState({loginFailed: true});
      }
    });
  }

  renderToolbar = () => <RenderToolbar/>

  handleSignUpClicked = () => {
    const { navigator } = this.props;
    navigator.pushPage({component: Register})
  }

  render() {
    const { user, navigator } = this.props;
    const { loginFailed } = this.state;

    return (
      <Page renderToolbar={this.renderToolbar}>
        {user.loggedIn ? (
          <div>
            <p>Already logged in!</p>
          </div>
        ) : (
          <>            
            <Card>
              <div className="">
                <h4>Do you already have an account with us?</h4>
              </div>
              
              <LoginForm onSubmit={this.handleSubmit}/>

              <p className="c-login__reset"><a onClick={() => navigator.pushPage({component: ResetPassword})}>Forgot your password?</a></p>
            </Card>

            {loginFailed && (
              <div className="u-text-center u-mt-1">
                <i>Your username or password is incorrect</i>
              </div>
            )}

            <Card>
              <div className="u-text-center u-mb-1">
                <h4>Dont have an account with us?</h4>
              </div>
            
              <Button className="button--large--cta" onClick={this.handleSignUpClicked}>Sign Up</Button>
            </Card>
          </>
        )}
      </Page>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions, ...BeanActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)