import React from 'react';
import { Page } from 'react-onsenui';
import { connect } from 'react-redux';
import RenderToolbar from '../RenderToolbar';
import { Form, reduxForm, Field } from 'redux-form';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import ons from 'onsenui';
import Loading from '../Loading';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';

function UserProfileForm(props) {
  const { popPage, handleSubmit, submitting, actions } = props;

  const isAndriod = ons.platform.isAndroid();

  const handleProfileSubmit = values => {
    return actions.saveUser({
      ...values,
      lat: 0,
      lng: 0
    }).then(response => {
      console.log(response)
    
      popPage();
    });
  }

  const renderToolbar = () => <RenderToolbar backAction={popPage}/>

  return (
    <Page className="user-profile" renderToolbar={renderToolbar}>
      <h1 className="u-text-center">User Profile</h1>

      <Form className="c-form" onSubmit={handleSubmit(handleProfileSubmit)}>
        <div className="c-form__row">
          <Field 
            id="first_name"
            name="first_name"
            placeholder="First Name"
            component={renderField}
            type="text"
            validate={Validators.required}
          />
        </div>

        <div className="c-form__row">
          <Field 
            id="last_name"
            name="last_name"
            placeholder="Last Name"
            component={renderField}
            type="text"
            validate={Validators.required}
          />
        </div>

        <div className="c-form__row">
          <Field 
            id="email"
            name="email"
            placeholder="Email"
            component={renderField}
            type="text"
            validate={[Validators.required, Validators.email, Validators.lowerCaseEmail]}
          />
        </div>

        <div className="c-form__row">
          <Field 
            id="phone"
            name="phone"
            placeholder="Phone"
            component={renderField}
            type="phone"
            validate={Validators.required}
          />
        </div>

        {/* <div className="c-form__row">
          <Field 
            id="address"
            name="address"
            placeholder="Address"
            component={renderField}
            type="text"
            validate={Validators.required}
          />
        </div> */}

        <div className="c-form__button-group">
          {submitting
            ? <Loading /> 
            : (
              <div className="c-form__button-group">
                <button 
                  type="submit" 
                  className={isAndriod ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'}
                >
                  Submit
                </button>
              </div>
            )}
        </div>
      </Form>
    </Page>
  );
}

UserProfileForm = reduxForm({
  form: 'UserProfileForm'
})(UserProfileForm)

function mapStateToProps(state) {
  return {
    initialValues: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileForm);