import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'reactstrap';
import { renderField } from '../RenderField';
import * as Validators from '../Validators';
import Loading from '../Loading';
import ons from 'onsenui';
import { Icon } from 'react-onsenui';


const LoginForm = props => {
  const { handleSubmit, submitting, error } = props;
  const isAndroid = ons.platform.isAndroid();

  return (
    <Form onSubmit={handleSubmit} className="c-form">
      {error && (
        <p className="c-form__error u-text-center">
          <Icon 
            icon={{default: 'ion-ios-alert', md: 'ion-ios-alert'}}
            size={{default: 20, md: 20}}
            className="u-mr-025"
          />
          {error}
        </p>
      )}
      
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field 
          name="email"
          id="email" 
          component={renderField} 
          type="text" 
          placeholder="Email"
          validate={[Validators.required, Validators.email]}
        />
      </div>
     
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        <Field
          name="password" 
          id="password" 
          component={renderField} 
          type="password"
          placeholder="Password"
          validate={Validators.required}
        />
      </div>
      <div className={isAndroid ? 'c-form__row c-form__row--material' : 'c-form__row'}>
        {submitting 
          ? <Loading/>
          : (
            <button type="submit" className={isAndroid ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'} disabled={submitting}>Login</button>
          )}
      </div>
    </Form>
  )
}

export default reduxForm({
  form: 'LoginForm'
})(LoginForm)