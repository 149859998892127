
import React, { Component } from 'react';

export default class StarRating extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stars: [],
      hovered: 0,
      selectedIcon: "★",
      deselectedIcon: "☆"
    };

    let outOf = props.outOf ? props.outOf : 5;

    for (var i = 0; i < outOf; i++) {
      this.state.stars.push(i + 1);
    }
  }

  changeRating(newRating) {
    if (this.props.onChange)
      this.props.onChange(newRating);
  }

  hoverRating(rating) {
    this.setState({
      hovered: rating
    });
  }

  render() {
    const { stars, hovered, deselectedIcon, selectedIcon } = this.state;
    const { rating } = this.props;

    return (
      <div className="c-feedback__star-rating">
        {stars.map((star, index) => {
          return (
            <span
              key={index}
              onClick={() => { this.changeRating(star); }}
              onMouseEnter={() => { this.hoverRating(star); }}
              onMouseLeave={() => { this.hoverRating(0); }}
            >
              {rating < star ?
                hovered < star ? deselectedIcon : selectedIcon
                : selectedIcon
              }
            </span>
          );
        })}
      </div>
    );
  };
};