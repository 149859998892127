import Echo from 'laravel-echo';
import { toastr } from 'react-redux-toastr';
import * as dayjs from 'dayjs';
import Pusher from 'pusher-js';

import {
  AUTH_CHECK,
  GET_AUTH_USER,
  SAVE_USER,
  LOGIN,
  LOGOUT,
  SIGNUP,
  PASSWORD_RESET,
  ADD_CART_ITEM,
  GET_CART_ITEMS,
  CLEAR_CART_ITEMS,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEM_VARIATION, 
  UPDATE_CART_ITEM_EXTRAS, 
  UPDATE_PICKUP_TIME,   
  UPDATE_ORDER_PICKUP_TIME, 
  REMOVE_CART_ITEM,
  PURCHASE,
  UPDATE_USER_BEAN_PREFERENCES,
  UPDATE_TILEVIEW_PREFERRED,
  ADD_USER_PAYMENT_METHOD,
  GET_USER_PAYMENT_METHODS,
  DESTROY_USER_PAYMENT_METHOD,
  UPDATE_USER_DEFAULT_PAYMENT_METHOD,
  UPDATE_USER_ORDER_STATUS,
  CREATE_PASSWORD_RESET,
  FIND_PASSWORD_RESET,
  SIGNUP_ACTIVATE,
  SEND_HELP_EMAIL,
  CANCEL_ORDER,
  DELAY_ORDER_PICKUP_TIME,
  SUBMIT_FEEDBACK,
  UPDATE_CART_ITEM_NOTES
} from '../actionTypes';

import * as api from '../api/api';
import ajaxCallError from './ajaxCallError';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export function authCheck() {
  return function(dispatch) {
    return new Promise(resolve => {
      const data = {
        type: AUTH_CHECK,
        payload: !!localStorage.getItem('access_token')
      };

      dispatch(data);
      resolve(data);
    });
  }
}

export function getAuthUser() {
  return function(dispatch) {
    return api
      .getAuthUser()
      .then(
        response => { 
          if(!window.echo) {
            const hostname = process.env.REACT_APP_API;
            const protocol = hostname.includes('local') || hostname === '127.0.0.1:8000' ? 'http://' : 'https://';
      
            const token = localStorage.getItem('access_token');
            const user = response.payload;
      
            window.echo = new Echo({
              broadcaster: 'pusher',
              key: process.env.REACT_APP_PUSHER_APP_KEY,
              cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
              encrypted: true,
              authEndpoint: `${protocol}${hostname}/api/broadcasting/auth`,
              auth: {
                headers: {
                  Authorization: `Bearer ${token}`,
                  Accept: 'application/json',
                },
              },
            });
      
            window.echo.private(`App.User.${user.id}`).notification((data) => {
              console.log('notification: ', data);
              const order = data.order;
      
              if (data.type === 'order-status-updated') {          
                console.log('Order Status Updated', `Order 2020${order.id}: ${order.order_status.name}`);
      
                // Declined
                if(order.order_status_id === 7) {
                  toastr.error('Order Declined', `Order 2020${order.id}: ${order.notes}`);
                } else {
                  toastr.success('Order Status Updated', `Order 2020${order.id}: ${order.order_status.name}`);
                }
      
                dispatch(updateUserOrderStatus(order.id, order.order_status.id));
                return;
              }
      
              if(data.type === 'order-pickup-time-updated'){
                toastr.success('Order pickup time updated', `Order 2020${order.id}: ${dayjs.utc(order.pickup_time).local().format('h:mma')} pickup`);
                return;
              }
      
              if(data.type === 'order-pickup-time-delayed'){
                toastr.warning(`Order 2020${order.id} pickup delayed til ${dayjs.utc(order.pickup_time).local().format('h:mma')}`, order.notes);
                dispatch(delayOrderPickupTime(order.id, order.pickup_time));
                return;
              }
            });

            return dispatch(getAuthUserSuccess(response));
          }
        },
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getAuthUserSuccess(response) {
  return {
    type: GET_AUTH_USER,
    payload: response.data || {}
  }
}

export function getUser() {
  return function(dispatch) {
    return api
      .getUser()
      .then(
        response =>  {
          if(!window.echo) {
            const hostname = process.env.REACT_APP_API;
            const protocol = hostname.includes('local') || hostname === '127.0.0.1:8000' ? 'http://' : 'https://';
            console.log('here', response.data)
      
            const token = localStorage.getItem('access_token');
            const user = response.data;
      
            window.echo = new Echo({
              broadcaster: 'pusher',
              key: process.env.REACT_APP_PUSHER_APP_KEY,
              cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
              encrypted: true,
              authEndpoint: `${protocol}${hostname}/api/broadcasting/auth`,
              auth: {
                headers: {
                  Authorization: `Bearer ${token}`,
                  Accept: 'application/json',
                },
              },
            });
      
            window.echo.private(`App.User.${user.id}`).notification((data) => {
              console.log('notification: ', data);
              const order = data.order;
      
              if (data.type === 'order-status-updated') {          
                console.log('Order Status Updated', `Order 2020${order.id}: ${order.order_status.name}`);
      
                // Declined
                if(order.order_status_id === 7) {
                  toastr.error('Order Declined', `Order 2020${order.id}: ${order.notes}`);
                } else {
                  toastr.success('Order Status Updated', `Order 2020${order.id}: ${order.order_status.name}`);
                }
      
                dispatch(updateUserOrderStatus(order.id, order.order_status.id));
                return;
              }
      
              if(data.type === 'order-pickup-time-updated'){
                toastr.success('Order pickup time updated', `Order 2020${order.id}: ${dayjs.utc(order.pickup_time).local().format('h:mma')} pickup`);
                return;
              }
      
              if(data.type === 'order-pickup-time-delayed'){
                toastr.warning(`Order 2020${order.id} pickup delayed til ${dayjs.utc(order.pickup_time).local().format('h:mma')}`, order.notes);
                dispatch(delayOrderPickupTime(order.id, order.pickup_time));
                return;
              }
            });
          }
          return dispatch(getUserSuccess(response));
        },
        error => dispatch(ajaxCallError(error))
      );
  }
}

function getUserSuccess(response) {
  return {
    type: GET_AUTH_USER,
    payload: response.data || {}
  }
}

export function saveUser(obj) {
  return function(dispatch) {
      return api
          .saveUser(obj)
          .then(
              response => dispatch(saveUserSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function saveUserSuccess(response) {
  return {
      type: SAVE_USER,
      payload: response.data || {}
  }
}

export function login(obj) {
  return function(dispatch) {
    return api
      .login(obj)
      .then(
        response =>  dispatch(loginSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function loginSuccess(response) {
  return {
    type: LOGIN,
    payload: response.data || {}
  }
}

export function signup(obj) {
  return function(dispatch) {
    return api
      .signup(obj)
      .then(
        response =>  dispatch(signupSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function signupSuccess(response) {
  return {
    type: SIGNUP,
    payload: response.data || {}
  }
}

export function logout() {
  return function(dispatch) {
    return new Promise(resolve => {
      const data = {
        type: LOGOUT,
      };

      dispatch(data);
      resolve(data);
    });
  }
}

export function createPasswordReset(obj) {
  return function(dispatch) {
    return api
      .createPasswordReset(obj)
      .then(
        response => dispatch(createPasswordResetSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function createPasswordResetSuccess(response) {
  return {
    type: CREATE_PASSWORD_RESET,
    payload: response.data || {}
  }
}

export function findPasswordReset(token) {
  return function(dispatch) {
    return api
      .findPasswordReset(token)
      .then(
        response => dispatch(findPasswordResetSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function findPasswordResetSuccess(response) {
  return {
    type: FIND_PASSWORD_RESET,
    payload: response.data || {}
  }
}

export function passwordReset(obj) {
  return function(dispatch) {
    return api
      .passwordReset(obj)
      .then(
        response => dispatch(passwordResetSuccess(response)),
        error => dispatch(ajaxCallError(error))
      );
  }
}

function passwordResetSuccess(response) {
  return {
    type: PASSWORD_RESET,
    payload: response.data || {}
  }
}

export function addCartItem(value) {
  return {
      type: ADD_CART_ITEM,
      payload: value
  }
}

export function getCartItems() {
  return {
      type: GET_CART_ITEMS,
      payload: JSON.parse(localStorage.getItem('cartItems')) || []
  }
}

export function removeCartItem(index) {
  return {
      type: REMOVE_CART_ITEM,
      payload: {
          index: index
      }
  }
}

export function clearCartItems() {
  return {
      type: CLEAR_CART_ITEMS,
      payload: []
  }
}

export function updateCartItem(index, number) {
  return {
      type: UPDATE_CART_ITEM,
      payload: {
          index: index,
          number: number
      }
  }
}

export function updateCartItemVariation(index, typeId, variationId) {
  return {
      type: UPDATE_CART_ITEM_VARIATION,
      payload: {
          index: index,
          typeId: typeId,
          variationId: variationId,
      }
  }
}

export function updateCartItemExtras(index, extras) {
  return {
      type: UPDATE_CART_ITEM_EXTRAS,
      payload: {
          index: index,
          extras: extras,
      }
  }
}

export function updateCartItemNotes(index, notes) {
  return {
      type: UPDATE_CART_ITEM_NOTES,
      payload: {
          index: index,
          notes: notes,
      }
  }
}

export function updatePickupTime(pickupTime) {
  return {
      type: UPDATE_PICKUP_TIME,
      payload: {
          pickupTime: pickupTime
      }
  }
}

export function purchase(obj) {
  return function(dispatch) {
      return api
          .purchase(obj)
          .then(
              response => dispatch(purchaseSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function purchaseSuccess(response) {
  return {
      type: PURCHASE,
      payload: response.data || {}
  }
}

export function updateUserBeanPreferences(obj, id) {
  return function(dispatch) {
      return api
          .updateUserBeanPreferences(obj, id)
          .then(
              response => dispatch(updateUserBeanPreferencesSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function updateUserBeanPreferencesSuccess(response) {
  return {
      type: UPDATE_USER_BEAN_PREFERENCES,
      payload: response.data || {}
  }
}

export function updateTileviewPreferred(id) {
  return function(dispatch) {
      return api
          .updateTileviewPreferred(id)
          .then(
              response => dispatch(updateTileviewPreferredSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function updateTileviewPreferredSuccess(response) {
  return {
      type: UPDATE_TILEVIEW_PREFERRED,
      payload: response.data || {}
  }
}

export function addUserPaymentMethod(id, obj) {
  return function(dispatch) {
      return api
          .addUserPaymentMethod(id, obj)
          .then(
              response => dispatch(addUserPaymentMethodSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function addUserPaymentMethodSuccess(response) {
  return {
      type: ADD_USER_PAYMENT_METHOD,
      payload: response.data || {}
  }
}

export function updateUserDefaultPaymentMethod(id, obj) {
  return function(dispatch) {
      return api
          .updateUserDefaultPaymentMethod(id, obj)
          .then(
              response => dispatch(updateUserDefaultPaymentMethodSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function updateUserDefaultPaymentMethodSuccess(response) {
  return {
      type: UPDATE_USER_DEFAULT_PAYMENT_METHOD,
      payload: response.data || {}
  }
}


export function getUserPaymentMethods(id) {
  return function(dispatch) {
      return api
          .getUserPaymentMethods(id)
          .then(
              response => dispatch(getUserPaymentMethodsSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function getUserPaymentMethodsSuccess(response) {
  return {
      type: GET_USER_PAYMENT_METHODS,
      payload: response.data || {}
  }
}

export function destroyUserPaymentMethod(obj) {
  return function(dispatch) {
      return api
          .destroyUserPaymentMethod(obj)
          .then(
              response => dispatch(destroyUserPaymentMethodSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function destroyUserPaymentMethodSuccess(response) {
  return {
      type: DESTROY_USER_PAYMENT_METHOD,
      payload: response.data || {}
  }
}

export function updateUserOrderStatus(orderId, statusId) {
  return {
    type: UPDATE_USER_ORDER_STATUS,
    payload: {
      orderId,
      statusId
    }
  }
}

export function signupActivate(obj) {
  return function(dispatch) {
      return api
          .signupActivate(obj)
          .then(
              response => dispatch(signupActivateSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function signupActivateSuccess(response) {
  return {
      type: SIGNUP_ACTIVATE,
      payload: response.data || {}
  }
}

export function cancelOrder(id) {
  return function(dispatch) {
      return api
          .cancelOrder(id)
          .then(
              response => dispatch(cancelOrderSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function cancelOrderSuccess(response) {
  return {
      type: CANCEL_ORDER,
      payload: response.data || {}
  }
}

export function updateOrderPickupTime(id, obj) {
  return function(dispatch) {
      return api
          .updateOrderPickupTime(id, obj)
          .then(
              response => dispatch(updateOrderPickupTimeSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function updateOrderPickupTimeSuccess(response) {
  return {
      type: UPDATE_ORDER_PICKUP_TIME,
      payload: response.data || {}
  }
}

export function delayOrderPickupTime(id, newTime) {
  return {
      type: DELAY_ORDER_PICKUP_TIME,
      payload: {
        id: id,
        pickup_time: newTime
      }
  }
}

export function sendHelpEmail(id, obj) {
  return function(dispatch) {
      return api
          .sendHelpEmail(id, obj)
          .then(
              response => dispatch(sendHelpEmailSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function sendHelpEmailSuccess(response) {
  return {
      type: SEND_HELP_EMAIL,
      payload: response.data || {}
  }
}

export function submitFeedback(id, obj) {
  return function(dispatch) {
      return api
          .submitFeedback(id, obj)
          .then(
              response => dispatch(submitFeedbackSuccess(response)),
              error => dispatch(ajaxCallError(error))
          );
  }
}

function submitFeedbackSuccess(response) {
  return {
      type: SUBMIT_FEEDBACK,
      payload: response.data || {}
  }
}