import React, { } from 'react';
import { Page } from 'react-onsenui';
import RenderToolbar from './RenderToolbar';
import SimpleMap from './SimpleMap';

function Directions(props) {
  const { popPage, pushPage, position, shop } = props;

  const renderToolbar = () => <RenderToolbar backAction={popPage} />

  return (
    <Page className="directions" renderToolbar={renderToolbar}>
      <SimpleMap
        marker={shop}
        pushPage={pushPage}
        position={position}
      />
    </Page>
  );
}

export default Directions;