import { GET_BEANS } from '../actionTypes';

const initialState = [];

const reducer = (state = initialState, { type, payload = null}) => {
  switch(type) {
    case GET_BEANS:
      return getBeans(payload);
    default:
      return state;
  }
}

function getBeans(payload) {
  return [
    ...payload.data
  ];
}

export default reducer;