import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Page, List, ListItem } from 'react-onsenui'
import { bindActionCreators } from 'redux';
import * as UserActions from '../actions/user';
import BuisnessForm from './preferences/BusinessForm';
import CoffeePreferencesForm from './preferences/CoffeePreferencesForm' 
import PaymentMethods from './preferences/PaymentMethods';
import UserProfileForm from './preferences/UserProfileForm';
import OrderHistory from './orders/OrderHistory';
import HelpForm from './help/HelpForm';

class Menu extends Component {

  handleLogoutClicked = () => {
    const { actions } = this.props;

    actions.logout();
  }

  handleCloseSidebar = () => {
    const { setActiveTab } = this.props;

    setActiveTab(1);
  }

  renderRow = (row, i) => {
    return (
      <ListItem 
        key={i}
        onClick={row.onClick}
      >
        <p>{row.text}</p>
      </ListItem>
    );
  }
  
  render() {
    const { user, pushPage } = this.props;

    const navItems = [
      {
        text: 'Your Profile',
        onClick: () => pushPage(UserProfileForm, 'user-profile'),
      },
      {
        text: 'Your Orders',
        onClick: () => pushPage(OrderHistory, 'user-history'),
      },
      // {
      //   text: 'Preferences',
      //   onClick: () => pushPage(CoffeePreferencesForm, 'coffee-preferences'), 
      // },
      {
        text: 'Payment Methods',
        onClick: () => pushPage(PaymentMethods, 'payment-methods') ,
      },
      {
        text: 'Register My Cafe',
        onClick: () => window.open('https://admin.quicky.cafe/admin/register/you', '_blank'),
      },
      {
        text: 'Contact',
        onClick: () => pushPage(HelpForm, 'get-help'),
      },
      {
        text: 'Terms and Conditions',
        onClick: () => window.open('https://quicky.cafe/buyer-terms', '_blank'),
      },
      {
        text: 'Privacy Policy',
        onClick: () => window.open('https://quicky.cafe/privacy-policy', '_blank'),
      },
      {
        text: 'Logout',
        onClick: () => this.handleLogoutClicked(),
      }
    ];

    return (
      <Page className="c-sidebar">
        <div className="c-sidebar__layout">
          <div className="c-sidebar__list">
            <h1 className="c-sidebar__name">{`${user.first_name} ${user.last_name}`}</h1>

            <List
              dataSource={navItems}
              renderRow={this.renderRow}
            />
          </div>
          <div className="c-sidebar__swipe" onClick={this.handleCloseSidebar}/>
        </div>
      </Page>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)

