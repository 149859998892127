import React, { useRef, useState } from 'react';
import { Page } from 'react-onsenui';
import { connect } from 'react-redux';
import RenderToolbar from '../RenderToolbar';
import ons from 'onsenui';
import Loading from '../Loading';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import * as UserActions from '../../actions/user';
import phone from "../icons/phone";

function HelpForm(props) {
  const { popPage, actions, user } = props;

  const textAreaRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const isAndriod = ons.platform.isAndroid();

  const handleSubmit = () => {
    if(!textAreaRef.current || !textAreaRef.current.value || textAreaRef.current.value == '') return;
    setSubmitting(true);

    return actions.sendHelpEmail(user.id, {
      'email_query': textAreaRef.current?.value
    }).then(response => {
      setSubmitting(false);
      toastr.success('Thank you for your query', 'Your question has been submitted', { timeOut: 3000 });
      popPage();
    });
  }

  const renderToolbar = () => <RenderToolbar backAction={popPage} />

  return (
    <Page className="user-profile" renderToolbar={renderToolbar}>
      <div className='c-form'>
        <h1 className="u-text-center">What can we help you with?</h1>

        <div className="c-form__text-area">
          <textarea ref={textAreaRef} placeholder="Enter your question or comment here and we'll be in touch!" rows={20}></textarea>
        </div>

        <div className="c-form__button-group">
          {submitting
            ? <Loading />
            : (
              <div className="c-form__button-group">
                <button
                  type="button"
                  disabled={submitting}
                  onClick={() => handleSubmit()}
                  className={isAndriod ? 'button--material button--large--cta u-mb-1' : 'button--large--cta u-mb-1'}
                >
                  Submit
                </button>
              </div>
            )}
        </div>

        <h2 className="u-text-center u-mt-1">
          <a className="u-text-decoration-none u-text-black" href="tel:+4733378901">{phone()}&nbsp;&nbsp;Call us instead</a>
        </h2>
      </div>
    </Page>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpForm);