import React from "react";
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { Image } from 'cloudinary-react';
import { Button } from 'react-onsenui';
import OrderDrinkForm from "./drink/OrderDrinkForm";
import * as dayjs from 'dayjs';
import DirectionsCar from '@material-ui/icons/DirectionsCar';

function findClosestMarker(markers, lat1, lon1) {    
  var pi = Math.PI;
  var R = 6371; //equatorial radius
  var distances = [];
  var closest = -1;

  for(let i=0; i<markers.length; i++ ) {  
      var lat2 = markers[i].lat;
      var lon2 = markers[i].lng;

      var chLat = lat2-lat1;
      var chLon = lon2-lon1;

      var dLat = chLat*(pi/180);
      var dLon = chLon*(pi/180);

      var rLat1 = lat1*(pi/180);
      var rLat2 = lat2*(pi/180);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
                  Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(rLat1) * Math.cos(rLat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;

      distances[i] = d;
      if ( closest == -1 || d < distances[closest] ) {
          closest = i;
      }
  }

  // (debug) The closest marker is:
  console.log(markers[closest]);

  return markers[closest];
}

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`, //&libraries=geometry,drawing,places
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    mapOptions: {
      styles: [
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eee5da"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eee5da"
            }
          ]
        },
        {
          "featureType": "landscape.natural.landcover",
          "stylers": [
            {
              "color": "#bea380"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ],
      streetViewControl: false,
      disableDefaultUI: true,
    },
    icons: {
      1: {
        url: '/images/marker_black.png',
        size: {width: 127, height: 191},
        scaledSize: {width: 21, height: 32},
        anchor: {x: 0, y: 32},
      },
      // {
      //   // path: 'M255.94421,108.08386a7.993,7.993,0,1,0,7.993,7.993A7.993,7.993,0,0,0,255.94421,108.08386ZM192,0C85.961,0,0,85.961,0,192c0,77.413,26.97,99.031,172.268,309.67a24.01047,24.01047,0,0,0,39.464,0C357.03,291.031,384,269.413,384,192,384,85.961,298.039,0,192,0Zm79.9303,307.90955a7.993,7.993,0,0,1-7.993,7.993H231.96515a7.993,7.993,0,0,1-7.993-7.993V251.95831h-79.9303v55.95124a7.993,7.993,0,0,1-7.993,7.993H104.07672a7.993,7.993,0,0,1-7.993-7.993V201.04773A47.87626,47.87626,0,0,1,64.11157,156.042a15.98606,15.98606,0,0,1,31.97211,0,16.01608,16.01608,0,0,0,15.986,15.98609h84.95593l74.90467,26.75164Zm47.95813-175.84662a31.97209,31.97209,0,0,1-31.97211,31.9721h-15.986v17.77448l-63.94422-22.83508V84.10474a7.99113,7.99113,0,0,1,13.64307-5.65l13.62811,13.64307h26.79163c5.45026,0,11.86462,3.95654,14.29755,8.83728l3.57684,7.1488h31.97211a7.993,7.993,0,0,1,7.993,7.993Z',
      //   path: 'M 191 191 Z M 192 0 C 85.961 0 0 85.961 0 192 c 0 77.413 26.97 99.031 172.268 309.67 a 24.0105 24.0105 0 0 0 39.464 0 C 357.03 291.031 384 269.413 384 192 C 384 85.961 298.039 0 192 0 Z Z Z',
      //   scale: 0.07,
      //   fillOpacity: 1,
      //   fillColor: '#1a7bc1',
      //   strokeColor: '#1a7bc1',
      //   strokeWeight: 0,
      // },
      2: {
        url: '/images/marker_black.png',
        size: {width: 127, height: 191},
        scaledSize: {width: 21, height: 32},
        anchor: {x: 0, y: 32},
      },
      // {
      //   path: 'M 191 191 Z M 192 0 C 85.961 0 0 85.961 0 192 c 0 77.413 26.97 99.031 172.268 309.67 a 24.0105 24.0105 0 0 0 39.464 0 C 357.03 291.031 384 269.413 384 192 C 384 85.961 298.039 0 192 0 Z Z Z',
      //   scale: 0.07,
      //   fillOpacity: 1,
      //   fillColor: '#113687',
      //   strokeColor: '#1a7bc1',
      //   strokeWeight: 0,
      // }
    }
  }),
  lifecycle({
    componentWillMount() {
      this.refs = {};

      this.setState({
        bounds: null,
        updateBoundsOnInit: true,
        center: {
          lat: -36.848461, lng: 174.763336
        },
        zoom: 12,
        markers: this.props.markers,
        draggable: this.props.draggable,
        cardOpen: null,
        currentLocation: null,
        directions: null,
        onMapMounted: ref => {
          this.refs.map = ref;

          // this.props.icons[1] = {
          //   ...this.props.icons[1],
          //   anchor: new window.google.maps.Point(192, 512),
          // }

          if(this.props.position){
            this.setState({
              currentLocation: this.props.position,
              center: {
                lat: this.props.position.lat,
                lng: this.props.position.lng
              },
              zoom: 15,
            });
          }
          console.log('on map mounted')
          
          // this.refs.map.fitBounds(bounds);
        },
        onBoundsChanged: () => {
          if(this.state.updateBoundsOnInit) {
            console.log('on bounds changed a', this.props.markers.length)

            if(typeof window.google !== 'undefined' && typeof this.refs.map !== 'undefined' && this.props.markers.length) {
              this.setState({updateBoundsOnInit: false});

              console.log('on bounds changed b')

              this.state.resetBounds();
            }
          }
        },
        setCardOpen: (i) => {
          this.setState({cardOpen: i});
        },
        resetBounds: () => {
          console.log('reset bounds')

          if(typeof window.google === 'undefined') return false;

          const bounds = new window.google.maps.LatLngBounds();

          if(this.props.position) {     
            const positionLatLng = new window.google.maps.LatLng(Number(this.props.position.lat), Number(this.props.position.lng));
            const positionMarker = new window.google.maps.Marker({position: positionLatLng});

            bounds.extend(positionMarker.position);

            const closest = findClosestMarker(this.props.markers, this.props.position.lat, this.props.position.lng);

            const latLng = new window.google.maps.LatLng(Number(closest.lat), Number(closest.lng));
            const m = new window.google.maps.Marker({position: latLng});

            bounds.extend(m.position);
          
          } else {
            this.props.markers.forEach(marker => {
              const latLng = new window.google.maps.LatLng(Number(marker.lat), Number(marker.lng));
              const m = new window.google.maps.Marker({position: latLng});

              bounds.extend(m.position);
            });
          }

          // Don't zoom in too far on only one marker
          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            const extra = 0.001;
            const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + extra, bounds.getNorthEast().lng() + extra);
            const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - extra, bounds.getNorthEast().lng() - extra);
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
          }

          this.refs.map.fitBounds(bounds);
        },
        handleOrderClicked: id => {
          this.props.setSelectedShop(id);
          this.props.pushPage(OrderDrinkForm, 'order-form', { shopId: id });
        },
        handleDirectionsClicked: (currentLocation, destination) => {
          if(typeof window.google === 'undefined') return;

          const DirectionsService = new window.google.maps.DirectionsService();

          DirectionsService.route({
            origin: new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng),
            destination: new window.google.maps.LatLng(destination.lat, destination.lng),
            travelMode: window.google.maps.TravelMode.WALKING,
          }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              this.setState({
                directions: result
              });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          });
        }
      })
    },
    componentDidUpdate(prevProps) {
      if(this.props.markers !== prevProps.markers) {
        let s = { 
          // center: this.props.markers[0].position,
          // zoom: 15,
          markers: this.props.markers 
        };

        if(this.props.markers.length) {
          // s.zoom = 12;

          if(this.props.markers.length === 1) {
            s.center = this.props.markers[0].position;
          }

          if(prevProps.markers.length && this.props.markers.length !== prevProps.markers.length) {
            this.state.resetBounds();
          } else if(this.state.updateBoundsOnInit) {
            // this.state.resetBounds();
            this.state.onBoundsChanged();
          }
        }

        this.setState(s);
      }
    },
    componentDidMount() {
    }
    }),
  withScriptjs,
  withGoogleMap,
)((props) =>
  <GoogleMap
    ref={props.onMapMounted}
    options={props.mapOptions}
    zoom={props.zoom}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
  >
    <DirectionsRenderer
      directions={props.directions}
      options={{
        polylineOptions: {
          strokeColor: '#000',
          strokeWeight: 5
        }
      }}
    />

    {props.currentLocation && (
      <Marker 
        key="currentLocation"
        position={props.currentLocation}
        icon="/images/current-location.png" />
    )}
    
    <MarkerClusterer maxZoom={12}>
      {props.markers.map((marker, index) => {
        const weekday = dayjs().format('dddd').toLowerCase();
        let isOpen = marker[`open_${weekday}`];

        if(isOpen && marker[`opening_time_${weekday}`] && marker[`closing_time_${weekday}`]){
          const now = dayjs();
          const openingTime = dayjs(marker[`opening_time_${weekday}`], 'HH:mm:ss');
          const closingTime = dayjs(marker[`closing_time_${weekday}`], 'HH:mm:ss').subtract(15, 'minutes');
          
          if(now.isBefore(openingTime) || now.isAfter(closingTime)){
            isOpen = false;
          }
        }

        return <Marker 
          key={index} 
          position={{lat: Number(marker.lat), lng: Number(marker.lng)}}
          icon={marker.beans.length > 0 && props.userBeanIds.includes(marker.beans[0].id) ? props.icons[2] : props.icons[1]}
          onClick={() => props.setCardOpen(index)}>
          {props.cardOpen === index &&
          <InfoWindow onCloseClick={() => props.setCardOpen(-1)}
        >
            <div className="c-map__card">
              {marker.banner && <Image cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME} publicId={marker.banner} className="c-map__card-image" />}

              <div className="c-map__card-content">
                <div className="c-map__card-title">
                  <h4>{marker.name}</h4>
                  
                  <a href={`https://www.google.com/maps/dir/?api=1&destination=${marker.lat},${marker.lng}`} target="_blank" rel='noreferrer'>              
                    <DirectionsCar className="u-width-30" />
                  </a>
                </div>

                {marker.beans.length > 0 && props.userBeanIds.includes(marker.beans[0].id) && (
                  <p className="c-cafelist__bean">{marker.beans[0].name}</p>
                )}

                <p>{marker.description}</p>

                  {marker.open ? null : <p className="c-cafelist__item__temporarily-closed">Sorry, we are temporarily not taking orders, we'll be back soon!</p>}

                <p>{marker.address}</p>
                
                <div className="u-d-flex u-mt-15">
                  <Button 
                    className="u-width-40 u-text-center u-mr-05"
                    disabled={!isOpen || !marker.open}
                    onClick={() => props.handleOrderClicked(marker.id)}
                  >
                    {isOpen && marker.open ? 'Order' : 'Closed'}
                  </Button>

                  {props.currentLocation ? <Button 
                    className="u-text-center u-width-60"
                    disabled={!isOpen || !marker.open}
                    onClick={() => props.handleDirectionsClicked(props.currentLocation, marker)}
                  >
                    Directions
                  </Button>
                  : null}
                </div>
              </div>
            </div>
          </InfoWindow>}
        </Marker>
      })}
    </MarkerClusterer>
  </GoogleMap>
)

export default Map;