import React from 'react';
import { Card } from 'react-onsenui';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';

export default function CurrentOrderBanners(props) {
  const { orders, onCurrentOrderClicked } = props;

  const getOrderStatusMessage = (order) => {
    let statusMessage = `Order ready at ${order.shop.name}, ${order.shop.address}`;

    switch (order.order_status_id) {
      case 1:
        statusMessage = `Order pending at ${order.shop.name}, ${order.shop.address}`;
        break;
      case 2:
        statusMessage = `Order queued at ${order.shop.name}, ${order.shop.address}`;
        break;
      case 3:
        statusMessage = `Order preparing at ${order.shop.name}, ${order.shop.address}`;
        break;
      default:
        break;
    }

    return statusMessage;
  }

  return (
    <div className="c-current-order-banner">
      <SwipeableViews>
        {orders.map((order, index) =>
          <div
            className="c-current-order-banner__container"
            key={`swipeable-${order.id}`}
          >
            <Card
              className="c-current-order-banner__card"
              onClick={onCurrentOrderClicked}
            >
              <p className="u-text-center">
                {getOrderStatusMessage(order)}
              </p>

              <p className="c-current-order-banner__card-cta">Tap to view</p>
            </Card>
          </div>
        )}
      </SwipeableViews>
    </div>
  );
}