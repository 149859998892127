import React, { Component } from 'react';
import { Page } from "react-onsenui";
import { connect } from "react-redux";
import * as BeanActions from '../actions/bean';
import FavourateCafes from './cafes/FavourateCafes';
import FindCafes from './cafes/FindCafes';
import CurrentOrderBanners from './orders/CurrentOrderBanners';
import CurrentOrders from './orders/CurrentOrders';
import { bindActionCreators } from 'redux';
import { selectCurrentOrders } from '../reducers/user';


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'list'    
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getBeans().then(response => {
      console.log(response);
    });
  }

  handleViewToggle = () => {
    this.setState({
      view: this.state.view === 'list' ? 'map' : 'list'
    })
  }

  onCurrentOrderClicked = () => {
    const { pushPage, currentOrders } = this.props;

    pushPage(CurrentOrders, 'current-orders', { orders: currentOrders });
  }

  render() {
    const { user, pushPage, popPage, shops, currentOrders } = this.props;
    const { view } = this.state;

    console.log('currentOrders', currentOrders)
    
    return (
      <Page className={`c-home c-home--${view}`}>
        {currentOrders && currentOrders.length > 0 && 
          <CurrentOrderBanners 
            orders={currentOrders}
            onCurrentOrderClicked={this.onCurrentOrderClicked}
          />}

        {user && user.shops && user.shops.length > 0 && view === 'list' && (
          <FavourateCafes
            view={view}
            pushPage={pushPage}
            popPage={popPage}
          />
        )}
        
        <FindCafes
          shops={shops}
          view={view}
          onViewChange={this.handleViewToggle}
          pushPage={pushPage}
          popPage={popPage}
        />
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    currentOrders: selectCurrentOrders(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...BeanActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)