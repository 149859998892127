import React, { Component, Fragment } from 'react';
import { Button, Row, Col, Modal, Card, Checkbox, Radio, Range } from 'react-onsenui';
import { injectStripe } from 'react-stripe-elements';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Loading from '../Loading';
import PaymentMethodsList from '../preferences/PaymentMethodsList';
import OrderTimePicker from '../order-time-picker/OrderTimePicker';
import PaymentMethodPreview from '../preferences/PaymentMethodPreview';
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

class CartForm extends Component {
  constructor(props) {
    super(props);

    let newState = {
      'products': {},
      'categories': {},
      'variations': {},
      'extras': {},
      'notes': null,
      favourite: false
    }

    props.shops.map(shop => {
      shop.categories.map(cat => {
        newState['categories'][cat.id] = {view: 'add'};

        cat.products.map(product => {
          newState['products'][product.index] = {
            shop_id: cat.shop_id,
            category_id: cat.id,
            available: product.available,
            number: product.number,
            variations: product.availableVariations,
            extras: product.availableExtras,
            notes: product.notes,
            showVariationsModal: false,
          };

          product.availableVariations.map(variation => {
            if(typeof newState['variations'][variation.type.id] === 'undefined') {
              newState['variations'][variation.type.id] = variation.id;
            }
          });

          product.availableExtras.map(extra => {
            newState['extras'][extra.id] = false;
          });
        });
      });
    });

    this.state = {
      ...newState,
      discountCode: '',
    };

    this.submit = this.submit.bind(this);
  }

  handleProductVariationsToggle = (index) => {
    this.setState({products: {
      ...this.state.products,
      [index]: {
        ...this.state.products[index],
        showVariationsModal: !this.state.products[index].showVariationsModal
      }
    }});
  }

  handleChangeNotes = (index, notes) => {
    this.setState({products: {
      ...this.state.products,
      [index]: {
        ...this.state.products[index],
        notes: notes
      }
    }});
  }

  getVariationsByType = variations => {
    let types = {};
    let aReturn = [];

    variations.map(variation => {
      if(typeof types[variation.type.id] === 'undefined') {
        types[variation.type.id] = {
          id: variation.type.id,
          name: variation.type.name,
          options: [],
        };
      }

      types[variation.type.id].options.push(variation);
    });

    Object.keys(types).map(id => aReturn.push(types[id]));

    return aReturn;
  }

  getExtrasByType = extras => {
    let types = {};
    let aReturn = [];

    extras.map(extra => {
      if(typeof types[extra.type.id] === 'undefined') {
        types[extra.type.id] = {
          name: extra.type.name,
          options: [],
        };
      }

      types[extra.type.id].options.push(extra);
    });

    Object.keys(types).map(id => aReturn.push(types[id]));

    return aReturn;
  }

  async submit(ev) {
    const { favourite } = this.state;
    const { handleSubmitting, handlePurchase, selectedPaymentMethod } = this.props;
    
    handleSubmitting();
    handlePurchase({paymentMethod: selectedPaymentMethod}, favourite);
  }

  handleDiscountCodeApply() {

  }

  render() {
    const { 
      shops,
      handleVariationChange,
      handlePickupTimeChanged,
      handleExtraAdd,
      handleExtraRemove,
      total,
      handleClear,
      handleRemove,
      submitting,
      errorMessage,
      paymentMethods,
      handleProductIncrement,
      handleProductDecrement,
      handleNotesChanged,
      initialPickupTime,
      discountCode,
      handleDiscountCodeChange,
      handleDiscountCodeApply,
      discount,

      paymentMethodsLoaded,
      selectedPaymentMethod,
      onPaymentMethodClicked,
      onNewPaymentMethodClicked,

      existingPaymentMethodIsOpen,
      onExistingPaymentMethodClicked,
      onCloseExistingPaymentMethodClicked
    } = this.props;

    let submitDisabled = !selectedPaymentMethod;

    if (submitting) {
      submitDisabled = true; 
    }

    const sortMilkOptions = (a, b) => {
      if (b.name === 'Small') return 1;
      if (b.name === 'Medium') return a.name === 'Small' ? -1 : 1;
      if (b.name === 'Large') return -1;
  
      return 0;
    }

    const getSelectedStrength = (selectedId, options) => {
      const index = options.findIndex((o) => o.id === selectedId);
      return index < 0 ? 50 : index * 50;
    }

    let newTotal = total;
    let serviceFee = 0;
    let totalPlusServiceFee = 0;

    if(selectedPaymentMethod && selectedPaymentMethod.card.country !== 'NZ'){
      serviceFee = total < 16 ? total * 0.07 : total * 0.085;
    } else {
      serviceFee = total < 16 ? total * 0.065 : total * 0.07;
    }

    if(discount && discount.type === 'percentage') {
      newTotal = newTotal - (newTotal * discount.amount / 100);
    }

    totalPlusServiceFee = Number(newTotal.toFixed(2)) + Number(serviceFee.toFixed(2));

    const dayOfWeek = dayjs().tz('Pacific/Auckland').format('dddd').toLowerCase();

    return (
      <div className="c-cart">
        {shops.map(shop => (
          <Fragment key={shop.id}>
            <Card className="c-cart__shop">
              <h3 className="u-text-black">{shop.name}</h3>

              {shop.categories.map(cat => (
                <div key={cat.id}>

                  {cat.products.length > 0 && cat.products.map(product => (
                    <Row key={product.index} className="c-cart__product mb-2">
                      
                      <Col width="50%">
                        <span>{product.name}</span>
                        <>                        
                          <Modal 
                            className="c-modal__edit-order"
                            isOpen={this.state.products[product.index] && this.state.products[product.index].showVariationsModal} 
                            toggle={() => this.handleProductVariationsToggle(product.index)}
                          >
                            <div className="c-drink__extras c-cart__product-extras">
                              <h3 className="u-mb-2">Modify</h3>
                              <span>{product.name}</span>
                              {this.getVariationsByType(product.availableVariations).map(type => {
                                return <div key={`variation_type_${type.id}`}>
                                  {type.name === 'Size' && (
                                    <>
                                      <div className="c-drink__sizes">
                                        {type.options.length > 0 && type.options.sort(sortMilkOptions).map(v => (
                                          <div key={`variation-${v.id}`} className={"c-drink__sizes__item" + (product.variations[type.id] === v.id ? ' c-drink__sizes__item--selected' : '')}>
                                            <Button className={`c-drink__sizes__${v.name}`} onClick={() => {handleVariationChange(product.index, type.id, v.id)}}>

                                              {v.name === 'Small' &&
                                                <svg className="svg_cup cup_small" viewBox="0 0 117 126">
                                                    <polygon className="st0" points="17.5,120.5 3.6,9.5 112.4,9.5 98.5,120.5"/>
                                                    <path d="M111.9,10L98,120H18L4.1,10H111.9 M113,9H3l14.1,112h81.8L113,9L113,9z"/>
                                                </svg>
                                              } 

                                              {v.name === 'Medium' &&
                                                  <svg className="svg_cup cup_medium" viewBox="0 0 135 162">
                                                      <polygon className="st0" points="21.6,156.5 4.6,9.5 129.4,9.5 112.4,156.5"/>
                                                      <path d="M128.9,10L112,156H22L5.1,10H128.9 M130,9H4l17.1,148h91.8L130,9L130,9z"/>
                                                  </svg>
                                              } 

                                              {v.name === 'Large' &&
                                                <svg className="svg_cup cup_large" viewBox="0 0 144 207">
                                                    <polygon className="st0" points="26.6,201.5 9.5,9.5 134.5,9.5 117.4,201.5"/>
                                                    <path d="M133.9,10L117,201H27L10.1,10H133.9 M135,9H9l17.1,193h91.8L135,9L135,9z"/>
                                                </svg>
                                              }

                                              <p className="c-drink__size__text">{v.name}</p>
                                            
                                            </Button>
                                          </div>
                                        ))}
                                      </div>
                                    </>

                                  )}

                                  {type.name === 'Strength' && type.options.length > 0 && product.variations[type.id] && (
                                    <div className="c-drink__extras-strength">
                                      <h4 className="u-mb-0">Strength</h4>
                                      
                                      <Range 
                                        value={getSelectedStrength(product.variations[type.id], type.options)}
                                        modifier='material'
                                        step={50}
                                        min={0}
                                        max={(type.options.length-1) * 50}
                                        onInput={(e) => handleVariationChange(product.index, type.id, type.options[e.target.value/50].id)}
                                      />

                                      <p className="range__labels">
                                        {type.options.map((o, i) =>
                                          <span key={i}>{o.name}</span>)}
                                      </p>
                                    </div>
                                  )}
                                  
                                  {type.name === 'Milk' && (
                                    <div className="c-drink__extras-milk">
                                      <h4 className="u-mb-0">{type.name}</h4>
                                      <div className={"btn-group btn-group-toggle"} data-toggle="buttons">
                                        <Row>
                                          {type.options.map((option, i) => (
                                            <Col>
                                              <label className={product.variations[type.id] === option.id ? ` button check-button check-button--selected ${option.name}` : ` button check-button ${option.name}`} key={i}>
                                                {option.name.charAt(0).toUpperCase()}{option.name.slice(1)}
                                                <Radio
                                                  name="milk" 
                                                  value={option.id} 
                                                  checked={product.variations[type.id] === option.id} 
                                                  onClick={(e) => {
                                                    handleVariationChange(product.index, type.id, option.id);
                                                  }}
                                                  className="mr-2" 
                                                />
                                              </label>
                                            </Col>
                                          ))}
                                        </Row>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              })}

                              {this.getExtrasByType(product.availableExtras).map((type, i) => (
                                <Fragment key={`extra_type_${i}`}>
                                  {type.name === 'Sugar' && (
                                    <div className="c-drink__extras c-drink__extras-other incrementable_extras">
                                      <>
                                        <h4 className="u-mb-0">{type.name}</h4>
                                        <div className={"btn-group btn-group-toggle"} data-toggle="buttons">
                                          <Row>
                                            {type.options.map((option, i) => (
                                              <Col>
                                                <label className={product.variations[type.id] === option.id ? ` button check-button check-button--selected ${option.name}` : ` button check-button ${option.name}`} key={i}>
                                                  {option.name.charAt(0).toUpperCase()}{option.name.slice(1)}
                                                  <Radio disabled />
                                                  {/* <Radio className={`mr-2 incrementor-radio ${valueCount < 1 ? 'unselected' : 'selected'}`} disabled/> */}

                                                  <div className="c-incrementor">
                                                    <Button onClick={() => handleExtraRemove(product.index, option.id)}><RemoveIcon/></Button>
                                                    <span className="c-incrementor__count">{product.extras && product.extras.filter(e => e === option.id).length}</span>
                                                    <Button onClick={() => handleExtraAdd(product.index, option.id)}><AddIcon/></Button>
                                                  </div>
                                                </label> 	
                                              </Col>
                                            ))}
                                          </Row>
                                        </div>
                                      </>
                                    </div>
                                  )}

                                  <div className="c-drink__extras-other">
                                    {((type.name === 'Sprinkling' ) || (type.name === 'Flavour') || (type.name === 'Extras')) && (
                                      <>
                                        <h4 className="u-mb-0">{type.name}</h4>
                                        <div className={"btn-group btn-group-toggle"} data-toggle="buttons">
                                          <Row>
                                            {type.options.map((option, i) => (
                                              <Col>
                                                <label className={product.extras.includes(option.id) ? ` button check-button check-button--selected ${option.name}` : ` button check-button ${option.name}`} key={i}>
                                                  {option.name.charAt(0).toUpperCase()}{option.name.slice(1)}
                                                  <Checkbox
                                                    name="sprinkling"
                                                    value={option.id} 
                                                    onClick={(e) => {
                                                      if (e.target.checked) {
                                                        handleExtraAdd(product.index, option.id)
                                                      } else {
                                                        handleExtraRemove(product.index, option.id)
                                                      }
                                                    }}
                                                    className="mr-2"
                                                    checked={product.extras.includes(option.id)}
                                                  />
                                                </label>
                                              </Col>
                                            ))}
                                          </Row>
                                        </div>
                                      </>
                                    )}
                                  </div>                                  
                                </Fragment>
                              ))}

                              <p>Notes</p>

                              <textarea name="notes" value={this.state.products[product.index].notes} onChange={e => this.handleChangeNotes(product.index, e.target.value)} className="u-mb-1 u-width-80" rows={4} />

                              <Button color="primary" className="c-btn u-mt-2" onClick={() => { handleNotesChanged(product.index, this.state.products[product.index].notes); this.handleProductVariationsToggle(product.index)}}>Done</Button>
                            </div>
                          </Modal>
                        </>
                      </Col>

                      <Col width="50%" className="u-text-right">
                        <div className="u-d-flex u-justify-content-end">
                          <div className="c-cart__quantity u-text-right">
                            <Button disabled={product.number < 1} onClick={() => handleProductDecrement(product.index)}><RemoveIcon/></Button>
                            <span>{product.number}</span>
                            <Button disabled={product.number > 9} onClick={() => handleProductIncrement(product.index)}><AddIcon/></Button>
                          </div>
                          
                          <span className="u-ml-05">${discount && discount.type === 'percentage' ? (product.price - (product.price * discount.amount / 100)).toFixed(2) : product.price.toFixed(2)}</span>
                        </div>
                      </Col>

                      <Col width="60%">
                        <ul className={`u-text-gray${product.notes ? ' u-mb-0' : ''}`}>
                          {Object.keys(product.variations).length > 0 && Object.keys(product.variations).map(i => {
                            let variation = product.variations[i];

                            if (variation && variation > 0) {
                              return (
                                <li key={`variation-${i}`} className="c-cart__product__details__extra">
                                  {product.availableVariations.find(v => v.id === variation).name}
                                </li>
                              );
                            }

                            return null;
                          })}

                          {product.extras && product.extras.length > 0 && product.extras.map((eId) => {
                            const extra = product.availableExtras.find(e => e.id === eId);
                            const extrasCount = product.extras.filter(e => e === eId).length;
                            return extrasCount > 1 ? `${extrasCount} x ${extra.name}` : extra.name;
                          })
                          .reduce((unique, extra) => unique.includes(extra) ? unique : [...unique, extra], [])
                          .map((extraText, i) => (
                            <li key={`extra-${i}`} className="c-cart__product__details__extra">{extraText}</li>
                          ))}
                        </ul>
                      </Col>

                      <Col width="40%" className="u-text-right u-mt-1">
                        <Button modifier="material--flat" onClick={() => this.handleProductVariationsToggle(product.index)}>
                          <EditIcon />
                        </Button>
                        
                        <Button modifier="material--flat" onClick={() => handleRemove(product.index)} className="u-ml-05">
                          <DeleteIcon />
                        </Button>
                      </Col>

                      {product.notes ? <Col width="100%" className="u-mb-15 u-text-italic">Notes: {product.notes}</Col> : null}
                    </Row>
                  ))}

                  <Row className="c-cart__product u-mt-1">
                    <Col className="mb-2" width="70%"><span>Service fee</span></Col>
                    <Col className="mb-2 u-text-right" width="30%"><span>${serviceFee.toFixed(2)}</span></Col>
                  </Row>

                  <Row className="c-cart__product">
                    <Col className="mb-2" width="100%"><small className="u-text-gray">Please note: if you are using an international card, the service fee may increase by up to 1.5%.</small></Col>
                  </Row>

                  <Row className="c-cart__product u-mt-1">
                    <Col className="mb-2" width="100%">
                      <div className="u-d-flex u-width-90">
                        <label className="u-flex-shrink-0 u-mr-05">Discount code{discount ? ' applied' : ''}:</label>
                        {discount ?
                          <span>{discount.amount}{discount.type === 'percentage' ? '% off' : ''}</span>
                        :
                          <>
                            <input type="text" value={discountCode} onChange={e => handleDiscountCodeChange(e.target.value)} className="u-width-30 u-flex-shrink-1 u-flex-grow-1 u-mr-05" />
                            <Button type="submit" onClick={handleDiscountCodeApply} className="c-btn u-flex-shrink-0 u-minwidth-auto u-pl-05 u-pr-05"><small>Apply</small></Button>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}

              <div className="c-cart__favourite">
                <Checkbox
                  checked={this.state.favourite}
                  onChange={event => this.setState({favourite: event.target.checked})}
                  modifier='material' 
                />

                <span>Save this order to your favourites?</span>
              </div>
            </Card>

            <Card className="c-cart__shop">
              <OrderTimePicker 
                closingTime={shop[`closing_time_${dayOfWeek}`]}
                onTimeChanged={(newTime) => handlePickupTimeChanged(newTime)} 
                initialTime={initialPickupTime}
              />
            </Card>
          </Fragment>
        ))}

        {paymentMethodsLoaded ? (
          <>
            {selectedPaymentMethod ? (
              <>
                <div className="u-d-flex u-justify-content-between u-align-items-center">
                  <p>Selected payment method</p>

                  {selectedPaymentMethod && (
                    <Button onClick={onExistingPaymentMethodClicked}>
                      Change card
                    </Button>
                  )}
                </div>

                <PaymentMethodPreview paymentMethod={selectedPaymentMethod} />
              </>
            ) : (
              <div className="u-d-flex">
                <Button 
                  type="button" 
                  className="button--large--cta" 
                  onClick={onNewPaymentMethodClicked}
                >
                  Add a new card
                </Button>
              </div>
            )}
          </>
        ) : <Loading/>}

        <div className="c-cart__buttons u-mt-1 u-mb-1">
          {!submitting && (
            <Button className="c-btn white" onClick={handleClear}>
              Clear
            </Button>
          )}
        </div>

        <div className="c-cart__buttons purchase">
          {submitting 
            ? <Loading/> 
            : (
              <>
                <div className="c-text__header">
                  <h2>All done. Thank you.</h2>
                </div>

                <Button 
                  onClick={this.submit} 
                  color="primary" 
                  className={"c-btn ml-2" + (submitting ? " ml-auto" : "")} 
                  disabled={submitDisabled}
                >
                  Purchase (${totalPlusServiceFee.toFixed(2)})
                </Button>

                <div className="c-payment-method__stripe-logo">
                  <a className="u-p-0 u-mt-15" href="https://stripe.com/nz/legal/consumer" target="_blank" rel="noreferrer">
                    <img  src="stripe-black.svg" />
                  </a>
                </div>
              </>
            )}
        </div>

        <Modal isOpen={existingPaymentMethodIsOpen}>
          <div className="c-modal c-modal--lg u-d-flex-col u-justify-content-between">
            {paymentMethods?.length > 0 ? (
              <PaymentMethodsList 
                paymentMethods={paymentMethods}
                onPaymentMethodClicked={onPaymentMethodClicked}
                selectedPaymentMethod={selectedPaymentMethod}
              />
            ) : <p>No card saved yet.</p>}

            <div className="u-d-flex-col">
              <Button
                type="button"
                className="u-mb-1"
                onClick={onNewPaymentMethodClicked}
              >
                Add a new card
              </Button>

              <Button 
                type="button" 
                onClick={onCloseExistingPaymentMethodClicked}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default injectStripe(CartForm);