import React, { Component } from 'react';
import { Page, Toolbar, BackButton, AlertDialog, Button, ProgressCircular, Card } from 'react-onsenui';
import * as UserActions from '../../actions/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AJAX_CALL_ERROR, LOGIN, PASSWORD_RESET } from '../../actionTypes';
import { SubmissionError } from 'redux-form';

class SignupActivate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      submitting: true,
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    const aToken = window.location.pathname.split('/');
    const sToken = aToken[aToken.length-1];

    actions.signupActivate(sToken).then(response => {
      if(response.type === AJAX_CALL_ERROR) {
        this.setState({ submitting: false, message: response.payload.response.data.message });
      } else {
        window.location.replace('/');
      }
    });
  }

  renderToolbar = () => {
    const { popPage } = this.props;

    return (
      <Toolbar>
        {/* <div className="left">
          <BackButton onClick={popPage}>
            Back
          </BackButton>
        </div> */}
        <div className="center u-text-black">
          Confirm Account
        </div>
      </Toolbar>
    );
  }

  render() {
    const { submitting } =  this.props;
    const { isOpen, title, content, sent, message } = this.state;
    
    return (
      <Page renderToolbar={this.renderToolbar}>
        <Card>
          {message ? 
            <div className="u-text-center u-text-red u-mb-1">
              <i>{message}</i>
            </div>
          : null}

          {submitting
            ? <div className="u-text-center"><ProgressCircular indeterminate/></div>
          : null}
        </Card>
        <AlertDialog isOpen={isOpen}>
          <div className="alert-dialog-title">{title}</div>
          <div className="alert-dialog-content">{content}</div>
          <div className="alert-dialog-footer">
            <Button 
              onClick={() => this.handleDialogClick(sent)} 
              className="alert-dialog-button"
            >
              Ok
            </Button>
          </div>
        </AlertDialog>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...UserActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupActivate);