export const required = value => value ? undefined : 'Required';

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined;

export const lowerCaseEmail = value =>
  value && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value) ?
    'Must be lowercase' : undefined;

export const oneRequired = value => value && value.length ? undefined : 'Required';

export const password = value => value.length < 8 ? 'Must be greater than 8 characters' : undefined;

export const letterAndNumber = value => 
  value && (!/\d/.test(value) || !/[A-Z]/i.test(value)) ?
    'Must include at least one letter and one number' : undefined;

export const confirmPassword = (value, allValues) => 
  typeof allValues.password !== 'undefined' && value === allValues.password ? undefined : 'Passwords must match';